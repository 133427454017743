import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const StudyTestApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "学习科目", "请输入我的学习科目, 比如英语", "学习科目"),
        new WidgetAppParamTextArea("content", "知识点", "请输入要考察的知识点, 每个知识点一行", "知识点"),
    ];

    const template = "StudyTest";
    const title = "学习测评";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default StudyTestApp;