import { useState, useCallback, useRef, useEffect } from 'react';
import FileSender from "./filesender";
import sparkMD5 from "spark-md5";


function useUploading(wsUrl: string) {
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [fileId, setFileId] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [statusText, setStatusText] = useState('');
    const [lastModified, setLastModified] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const fileInputElement = useRef<HTMLInputElement | null>(null);



    const onProgress = useCallback((sentBytes, totalBytes) => {
        setProgress((sentBytes / totalBytes) * 100);
    }, []);
    const onFinished = useCallback((fileId, fileUrl) => {
        setFileId(fileId)
        setFileUrl(fileUrl)
        setFileUploaded(true)
    }, [fileId, fileUrl]);

    const startUpload = useCallback((acceptedTypes) => {
        setFileUploaded(false)
        if(fileInputElement.current) {
            fileInputElement.current.accept = acceptedTypes;
            fileInputElement.current.click();
        }


    }, []);

    const calculateMD5 = (file: File): Promise<string> => {
        setStatusText("正在计算文件校验码")
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = function() {
                const binaryStr = reader.result as string;
                const md5 = sparkMD5.hashBinary(binaryStr);
                resolve(md5);
            };
            reader.onerror = function() {
                reject('文件MD5计算失败');
            };
            reader.readAsBinaryString(file);
        });
    }

    const clear = () => {
        setFileName('');
        setFileSize(0);
        setFileId('');
        setFileUrl('');
        setStatusText('');
        setLastModified(0);
        setUploading(false);
        setProgress(0);
        setErrorMessage('');
        setFileUploaded(false);

    }


    useEffect(() => {
        // 初始化文件输入元素
        fileInputElement.current = document.createElement("input");
        fileInputElement.current.type = "file";
        fileInputElement.current.onchange = async (e) => {
            const file = (e.target as HTMLInputElement).files![0];
            if (file) {
                setStatusText("正在准备文件")
                setFileName(file.name);
                setFileSize(file.size);
                setLastModified(file.lastModified);
                setUploading(true);
                setProgress(0);
                setErrorMessage('');
                const md5 = await calculateMD5(file)
                const sender = new FileSender(wsUrl, file, md5, onProgress, onFinished);
                try {
                    setStatusText("正在上传数据")
                    await sender.startSend();
                    setFileId(sender.fileId)
                    setStatusText("上传完成")

                } catch (e: any) {
                    setErrorMessage(e.message);
                    setStatusText("上传失败 " + e.message)
                } finally {
                    setUploading(false);
                }
            }
        }
    }, [wsUrl, onProgress]);


    return {
        fileName,
        fileSize,
        fileId,
        fileUrl,
        statusText,
        lastModified,
        uploading,
        progress,
        errorMessage,
        startUpload,
        fileUploaded,
        clear
    };
}

export default useUploading;
