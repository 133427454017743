import {FormProvider} from "react-hook-form";
import {Form, InputGroup, Modal} from "react-bootstrap";
import Select from "react-select";
import {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React from "react";
import {RobotSelector} from "./RobotSelector";
import {FormInput} from "../../../components";

export interface ChatRenameDialogProps {
    visible: boolean
    onHide: () => void
}
export const ChatRenameDialog = (props: ChatRenameDialogProps) => {
    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <h4 className="modal-title">重命名对话</h4>
            </Modal.Header>
            <Modal.Body className="p-4">
                <FormInput
                    label="会话名称"
                    type="text"
                    name="text"
                    containerClass={"mb-3"}
                    key="text"
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                        props.onHide()
                    }}
                >
                    取消
                </button>
                <button type="submit"
                        className="btn btn-primary waves-effect waves-light">确定
                </button>

            </Modal.Footer>

        </Modal>
    )
}