import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamSelect, WidgetAppParamTextArea } from "../WidgetAppParam";

const FlowChartApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamSelect("template", "模板", "选择模板", "选择模板", [
            {value: "Sequence diagram", label: "序列图"},
            {value: "Usecase diagram", label: "用例图"},
            {value: "Class diagram", label: "类图"},
            {value: "Object diagram", label: "对象图"},
            {value: "Activity diagram", label: "程序活动图"},
            {value: "Component diagram", label: "组件图"},
            {value: "Deployment diagram", label: "部署图"},
            {value: "State diagram", label: "状态图"},
            {value: "Timing diagram", label: "时序图"},
            {value: "JSON data", label: "JSON数据图"},
            {value: "YAML data", label: "YAML数据图"},
            {value: "EBNF diagram", label: "EBNF图"},
            {value: "Regex diagram", label: "正则表达式图"},
            {value: "Network diagram", label: "网络图"},
            {value: "UI mockups", label: "UI原型图"},
            {value: "Archimate diagram", label: "Archimate业务图"},
            {value: "Specification and Description Language", label: "SDL图"},
            {value: "Ditaa diagram", label: "Ditaa图"},
            {value: "Gantt diagram", label: "甘特图"},
            {value: "MindMap diagram", label: "脑图"},
            {value: "WBS diagram", label: "WBS图"},
            {value: "Mathematics", label: "数学公式"},
            {value: "Entity Relationship", label: "实体关系图"},
        ]),
        new WidgetAppParamTextArea("code", "流程图内容", "输入流程图的内容", "流程图内容"),
    ];

    const template = "FlowChart";
    const title = "流程图绘制";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default FlowChartApp;