import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import profileImg from "../../../assets/images/users/user-1.jpg";
import useUploading from "../../../utils/uploading";
import Conf from "../Utils/Conf";
import Spinner from "../../../components/Spinner";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";



const StyleEditorApp = () => {
    const avatarUploader = useUploading(Conf.urlSecaiFoSend)
    const [avatarUrl, setAvatarUrl] = useState("")

    useEffect(() => {

        if(avatarUploader.fileId!='') {
            console.log("file uploaded " + avatarUploader.fileId + " " + avatarUploader.fileUrl)
            auth_fetch_post_json(Conf.urlWebCustomSetStr, {
                k: "avatar_default_url",
                v: avatarUploader.fileUrl
            }).then((res: any) => {
                if(res['status']) {
                    setAvatarUrl(avatarUploader.fileUrl)
                    alert("修改成功")
                } else {
                    alert("修改失败")
                }
            })

        }
    }, [avatarUploader.fileId]);


    useEffect(() => {
        auth_fetch_post_json(Conf.urlWebCustomGetStr, {
            k: "avatar_default_url",
        }).then((res: any) => {
            if(res['status']) {
                setAvatarUrl(res['v'])
            } else {

            }
        })
    }, []);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('风格编辑'))
    }, []);
    return (
        <>
            <Row>
                <Col md={6} xl={4}>
                    <Card style={{height: "256px"}}>
                        <Card.Header>默认头像</Card.Header>
                        <Card.Body  className="text-center">
                            <div className={"mb-3"}>
                                <img
                                    src={avatarUrl}
                                    className="rounded-circle avatar-lg img-thumbnail"
                                    alt=""
                                />
                            </div>
                            <div>
                                <Button disabled={avatarUploader.uploading} onClick={() => {
                                    avatarUploader.startUpload("image/png, image/jpeg")
                                }}>
                                    <span hidden={!avatarUploader.uploading} >
                                                <Spinner
                                                    className="spinner-border-sm me-1"
                                                    color="white"
                                                />
                                    </span>
                                    <span>更换</span></Button>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default StyleEditorApp;
