import React, {useEffect} from "react";

import AllRoutes from "./routes/Routes";

import { configureFakeBackend } from "./helpers";


// For Default import Default.scss
// import './assets/scss/Default.scss';

// For Saas import Saas.scss
// import './assets/scss/Saas.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
import "./assets/scss/Creative.scss";

// For Purple demo import Purple.scss
// import './assets/scss/Purple.scss';

// For Material demo import Material.scss
// import './assets/scss/Material.scss';


// Other
import './assets/scss/Landing.scss';
import "./assets/scss/Icons.scss";

// configure fake backend
configureFakeBackend();

const App = () => {
    useEffect(() => {
        fetch("/version.json", {
            headers: {
                method: "GET",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                document.title = data['versionName'];
            })
            .catch(error => {
                console.log("获取前端版本号失败")
            })
        }, [])
  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
    </>
  );
};

export default App;
