import React, { useCallback, useEffect, useState, useRef } from "react";
import {set, useForm} from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Col,
  Tooltip, Alert, Button, ButtonGroup, Form, InputGroup, Collapse,
} from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import 'highlight.js/styles/monokai.css';  // 引入你选择的样式

import './messages.css'
// components
import { FormInput } from "../../../components/";
import Loader from "../../../components/Loader";

// default data
import {messages, ChatMessage, ChatUser, users, UserInputFile, KbChatConf, chatFileUser} from "./data";

import avatar1 from "../../../assets/images/users/user-1.jpg";

import Conf from "../Utils/Conf";
import {ssePost, sseGet, sseWs} from "../Utils/HttpUtil";
import Spinner from "../../../components/Spinner";
import { withSwal } from "react-sweetalert2";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import ChatInput from "./ChatInput";
import useUploading from "../../../utils/uploading";
import ChatFileCard from "./ChatFileCard";
import ChatFileInBubble from "./ChatFileInBubble";
import {chatWsSendJson} from "../../../utils/chat_ws";
import useTts from "../../../utils/tts";
import useWebAppConfig, {ModelConfig} from "../../../utils/WebAppConfig";
import classNames from "classnames";
import {KbDocBase} from "../KbChat/KbDocBases";
import {RobotSelector} from "./RobotSelector";
import {ChatNewDialog} from "./ChatNewDialog";
import {ChatRenameDialog} from "./ChatRenameDialog";
import {ChatHistoryDialog} from "./ChatHistoryDialog";
import {ChatMessageComponent} from "./ChatMessageComponent";
import {ChatDialogSelector} from "./ChatDialogSelector";
import {ModelSelector} from "./ModelSelector";
import chatUsers from "./ChatUsers";



interface ChatAreaProps {
  chatDocBaseUuid?: string;
  kbChatConf?: KbChatConf;
  isUsingInKb: boolean;
}
interface SwalProps {
  swal: any;
}

export interface ChatDialog {
  dialogName: string
  dialogId: number
  robotId: string
}


// ChatArea
const ChatArea = withSwal(({ chatDocBaseUuid, swal, kbChatConf, isUsingInKb }: ChatAreaProps & SwalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userMessages, setUserMessages] = useState<ChatMessage[]>([]);
  const [prompts, setPrompts] = useState([]);
  const [inputingMessage, setInputingMessage] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const [userInputFiles, setUserInputFiles] = useState<UserInputFile[]>([])
  const foUploader = useUploading(Conf.urlSecaiFoSend)
  const [enableSpeak, setEnableSpeak] = useState(false)
  const tts = useTts();
  const messageAudioControl = useRef<HTMLAudioElement>();
  const api = new APICore();
  const webAppConfig = useWebAppConfig()
  const [isChatNewDialogOpen, setIsChatNewDialogOpen] = useState(false)
  const [isChatRenameDialogOpen, setIsChatRenameDialogOpen] = useState(false)
  const [isChatHistoryDialogOpen, setIsChatHistoryDialogOpen] = useState(false)
  const [dialogId, setDialogId] = useState(-1)
  const [dialogSelectorRefresh, setDialogSelectorRefresh] = useState(0)
  const [toUser] = useState<ChatUser>({
    id: 9,
    name: "我",
    avatar: avatar1,
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Friends",
  });

  const [selectedUser, setSelectedUser] = useState<ChatUser>(users[0]);
  const [selectedModel, setSelectedModel] = useState<ModelConfig | null>(null)
  const [isChatting, setIsChatting] = useState(false)

  const [socket, setSocket] = useState<WebSocket|null>(null)

  useEffect(() => {
    console.log("getMessagesForUser.... " + dialogId)
    if (selectedUser) {
      setLoading(true);
      const api = new APICore();

      auth_fetch_post_json(Conf.urlApiChat2List, {
        'dialogId': dialogId
      })
          .then((data: any) => {
            const messages = data.map((m: any) => {
              const robot = selectedUser
              const user = toUser
              return {
                id: m.id,
                from: m.role == "user" ? user : robot,
                to: m.role == "robot" ? robot : user,
                sendOn: m.sendOn,
                message: m.message,
                files: m.files,
                has_from: m.has_from,
                from_uuid: m.from_uuid
              }
            })
            setUserMessages(messages)
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error:', error);
            setLoading(false)
          });
    }
  }, [dialogId]);

  useEffect(() => {
    getPromptDemos()
  }, [selectedUser]);


  useEffect(() => {
    webAppConfig.loadWebAppConfig()
  }, []);


  useEffect(() => {
    if (foUploader.fileUploaded) {
      console.log("new file uploaded " + foUploader.fileId)
      const files = [...userInputFiles]
      files.push({
        fileName: foUploader.fileName,
        fileId: foUploader.fileId,
        fileSize: foUploader.fileSize
      })
      setUserInputFiles(files)
    }
  }, [foUploader.fileUploaded])
  const handleUploadFile = () => {
    const mimePdfAndTextAndDocx = "application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    foUploader.startUpload(mimePdfAndTextAndDocx)

  }
  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
      yup.object().shape({
        newMessage: yup.string().required("Please enter your messsage"),
      })
  );

  const getPromptDemos = useCallback(() => {
    if (!selectedUser) {
      return
    }
    auth_fetch_post_json(Conf.urlPromptDemoList, {
      "robot_id": selectedUser.robotId,
    })
        .then((data: any) => {
          setPrompts(data)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }, [selectedUser])


  /*
   * form methods
   */
  const methods = useForm({resolver: schemaResolver});
  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
  } = methods;

  /**
   * sends the chat message
   */
  const sendChatMessage = async (values: { newMessage: string }) => {
    console.log("sendChatMessage " + dialogId)
    let dlgid = dialogId
    let selUser = selectedUser
    if (dialogId == -1) {
      console.log("创建新对话")
      const jo = await auth_fetch_post_json(Conf.urlApiChat2DialogCreate, {
        'dialogName': '未命名会话',
        'robotId': isUsingInKb? 'chatFile' : 'genericHelperA',
        'where': isUsingInKb? 'kb': 'chat'
      }) as any
      const dialogId = jo.dialogId
      setDialogId(dialogId)
      if(isUsingInKb) {
        setSelectedUser(chatFileUser)
        selUser = chatFileUser
        console.log("user set to " + JSON.stringify(selUser))
      }
      dlgid = dialogId
    }

    if(userMessages.length == 0) {
      const jo = await auth_fetch_post_json(Conf.urlApiChat2DialogTitleGen, {
        'model_name': selectedModel?.modelName,
        'dialogId': dlgid,
        'message': values.newMessage
      }) as any
      if(jo.status) {
        setDialogSelectorRefresh(dialogSelectorRefresh + 1)
      }
    }

    setTextareaHeight('auto');
    let newUserMessages = [...userMessages];
    newUserMessages.push({
      id: userMessages.length + 1,
      from: toUser,
      to: selUser,
      message: {
        type: "text",
        value: values["newMessage"]
      },
      files: userInputFiles,
      sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
    });
    setUserInputFiles([])

    newUserMessages.push({
      id: userMessages.length + 1,
      from: selUser,
      to: toUser,
      message: {type: "typing", value: 'loading...'},
      sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
    })
    setUserMessages(newUserMessages);

    const outgoingJson = {
      'model_name': selectedModel?.modelName,
      'message': values["newMessage"],
      'robot': selUser.robotId,
      'dialogId': dlgid,
      'docbase_uuid': chatDocBaseUuid,
      'kb_chat_conf': kbChatConf,
      "stream": true,
      'file_ids': userInputFiles.map((file: UserInputFile) => {
        return file.fileId
      })
    }

    const handleReceivedData = (data: string) => {
      let otherNewMessages = [...newUserMessages]
      let parts = data.split("|\n|\n")
      parts.forEach((part: string) => {
        if (part != "") {
          const obj = JSON.parse(part)
          if (obj['event'] == 'token') {
            msg += obj['data']
            otherNewMessages[otherNewMessages.length - 1]['from'] = selUser
            otherNewMessages[otherNewMessages.length - 1]['to'] = toUser
            otherNewMessages[otherNewMessages.length - 1]['message'] = {type: "text", value: msg}
            setUserMessages(otherNewMessages);
          } else if (obj['event'] == 'from_info') {
            otherNewMessages[otherNewMessages.length - 1]['from'] = selUser
            otherNewMessages[otherNewMessages.length - 1]['to'] = toUser
            otherNewMessages[otherNewMessages.length - 1]['from_uuid'] = obj['from_uuid']
            setUserMessages(otherNewMessages);
          } else if (obj['event'] == 'error') {
            swal.fire({
              title: '错误',
              text: obj['data'],
              icon: 'error',
              confirmButtonText: '确定'
            })
          }
        }
      })
    }

    let msg = ''

    setIsChatting(true)
    await sseWs(
        Conf.urlChat2Ws,
        outgoingJson,
        (socket)=> {
          setSocket(socket)
        },
        (data: any) => {
          console.log("data: " + data)
          handleReceivedData(data)
        },
        () => {
          setIsChatting(false)
          if (enableSpeak) {
            tts.callTts("0", msg, (data: any) => {
              console.log("callback " + data)
            })
          }
        },
        (msg: string) => {
          setIsChatting(false)
        }
    )
    reset();
  };

  const cleanMessages = () => {
    auth_fetch_post_json(Conf.urlApiChatClean, {
      'robotId': selectedUser.robotId
    })
        .then((data: any) => {
              setUserMessages([])
            }
        )
        .catch((error) => {
              console.error('Error:', error);
            }
        );
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (elementRef && elementRef.current && elementRef.current.scrollIntoView) {
        elementRef.current.scrollIntoView({behavior: 'smooth'})
      }
    })
    return <div ref={elementRef}/>
  }


  return (
      <div style={{
        display: "flex",
        flex: 1,
        height: '100%',
        flexDirection: "column",
        backgroundColor: "#f8f9fa",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: '#f8f9fa',
          padding: '10px',
          borderBottom: '1px solid #e5e5e5',
        }}>

          <ModelSelector mode={isUsingInKb? 'kb': 'chat'} onModelChanged={(model)=> {
            setSelectedModel(model)
          }} />

          <ChatDialogSelector
              isUsingInKb={isUsingInKb}
              selectedDialogId = {dialogId}
              onNewClicked={async () => {
                console.log("isUsingInKb: " + isUsingInKb)
                if(isUsingInKb) {
                  const jo = await auth_fetch_post_json(Conf.urlApiChat2DialogCreate, {
                    'dialogName': '未命名会话',
                    'robotId': 'chatFile',
                    'where': isUsingInKb? 'kb': 'chat'
                  }) as any
                  setDialogId(jo.dialogId)
                  setSelectedUser(chatFileUser)
                  setDialogSelectorRefresh(dialogSelectorRefresh + 1)
                } else {
                  setIsChatNewDialogOpen(true)
                }

              }}
              onRenameClicked={() => {
                setIsChatRenameDialogOpen(true)
              }}
              onRemoveClicked={() => {
                console.log("remove")
              }}
              onHistoryClicked={() => {
                setIsChatHistoryDialogOpen(true)
              }}
              onDialogChanged={(dialogId: number, robotId: string) => {
                console.log("dialogId changed " + dialogId)
                setDialogId(dialogId)
                if(isUsingInKb) {
                  setSelectedUser(chatFileUser)
                } else {
                  const userFound = users.find((u: ChatUser) => u.robotId == robotId)
                  if(userFound) {
                    setSelectedUser(userFound)
                  }
                }

              }}
              refresh={dialogSelectorRefresh}
          />
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
            <Button
                size={"sm"}
                variant={"outline-secondary"}
                className="waves-effect waves-light me-2"
                onClick={() => setEnableSpeak(!enableSpeak)}
            >
              <i className={enableSpeak ? "fe-volume-1" : "fe-volume-x"}></i>
            </Button>
            <Button size={"sm"} variant={"outline-secondary"} className="waves-effect waves-light me-2"
                    onClick={() => cleanMessages()}> <i className="fe-trash-2"></i></Button>
            <Dropdown as={ButtonGroup} size={"sm"}>
              <Dropdown.Toggle variant="outline-secondary">
                <i className="mdi mdi-chevron-down">提问帮助</i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Header>你还可以这样提问</Dropdown.Header>
                {
                  prompts.map((prompt: any) => {
                    return (
                        <Dropdown.Item key={"prompt_demo_id_" + prompt.prompt_demo_id} onClick={async () => {
                          await sendChatMessage({
                            'newMessage': prompt.prompt
                          })
                        }}>{prompt.prompt}</Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>


        {loading && <Loader/>}
        <div
            style={{
              flex: 1,
              overflowY: 'auto',
              padding: '20px',
            }}
            id="chatScrollBar"
        >
          <ul
              className="conversation-list"
          >
            {(userMessages || []).map((message, index) => {
              return (
                  <ChatMessageComponent key={index} message={message} toUser={toUser}/>
              );
            })}
            <AlwaysScrollToBottom/>
          </ul>
        </div>

        <div style={{
          padding: '10px',
          backgroundColor: '#f8f9fa',
          borderTop: '1px solid #e5e5e5',
        }}>
          <div className="row">
            {
                foUploader.uploading && (
                    <Col xl={12} lg={12}>
                      <Alert color={'danger'}>正在发送文件</Alert>
                    </Col>
                )
            }
            {
              userInputFiles.map((file: UserInputFile) => {
                return (<Col xl={6} lg={6}>
                  <ChatFileCard key={file.fileId} userInputFile={file} onCloseClicked={() => {
                    const files = userInputFiles.filter((f: UserInputFile) => {
                      return f.fileId != file.fileId
                    })
                    setUserInputFiles(files)
                  }}></ChatFileCard>
                </Col>)
              })
            }
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <div style={{
              flex: '1'
            }}>
              <FormInput
                  type="textarea"
                  name="newMessage"
                  className="border-0 flex-grow-1"
                  placeholder="随便说点什么, 回车发送，Shift+Enter换行"
                  register={register}
                  key="newMessage"
                  errors={errors}
                  control={control}
                  value={inputingMessage}
                  disabled={isChatting}
                  style={{
                    overflow: 'hidden',
                    resize: 'none',
                    height: textareaHeight,
                    marginRight: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                  onKeyDown={async (e: any) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      if (inputingMessage != '') {
                        await sendChatMessage({
                          'newMessage': inputingMessage
                        });
                        setInputingMessage('');
                        setTimeout(() => {
                          e.target.style.height = 'auto'; // 重置高度
                          e.target.style.height = e.target.scrollHeight + 'px'; // 设置为内容的高度
                        }, 0);
                      }
                    }
                  }}
                  onChange={(e: any) => {
                    setInputingMessage(e.target.value);
                    // 动态调整高度
                    setTimeout(() => {
                      e.target.style.height = 'auto'; // 重置高度
                      e.target.style.height = e.target.scrollHeight + 'px'; // 设置为内容的高度
                    }, 0);
                  }}
              />
            </div>
            <div className="btn-group">
              {
                <Button hidden={!webAppConfig.allowFileInChat} className="btn btn-light"
                        onClick={handleUploadFile}>
                  <i className="fe-paperclip"></i>
                </Button>
              }
              {
                  !isChatting && <button
                      type="button"
                      className="btn btn-success chat-send w-100"
                      disabled={inputingMessage == ''}
                      onClick={async () => {
                        if (inputingMessage != '') {
                          await sendChatMessage({
                            'newMessage': inputingMessage
                          })
                          setInputingMessage('')
                        }
                      }}
                  >
                    <i className="fa fa-play"></i>
                  </button>
              }

              {
                  isChatting && <button
                      type="button"
                      className="btn btn-danger chat-send w-100"
                      onClick={() => {
                        if(socket) {
                          socket.close()
                          setSocket(null)
                          const xs = [...userMessages]
                          if(xs.length > 0) {
                            xs[xs.length - 1]['message']['type'] = 'cancel'
                            setUserMessages(xs);
                          }
                        }
                      }}
                  >
                    <i className="fa fa-stop"></i>
                  </button>
              }
            </div>
          </div>
        </div>
        <audio src={tts.ttsResult} controls={true} autoPlay={enableSpeak} hidden={true}></audio>
        <ChatNewDialog
            where={isUsingInKb? 'kb': 'chat'}
            onCreated={(dialogId: number, robotId: string) => {
              setDialogId(dialogId)
              const userFound = users.find((u: ChatUser) => u.robotId == robotId)
              if(userFound) {
                setSelectedUser(userFound)
              }
              setDialogSelectorRefresh(dialogSelectorRefresh + 1)
            }}
            visible={isChatNewDialogOpen}
            onHide={() => {
          setIsChatNewDialogOpen(false)
        }}></ChatNewDialog>
        <ChatRenameDialog visible={isChatRenameDialogOpen} onHide={() => {
          setIsChatRenameDialogOpen(false)
        }}></ChatRenameDialog>
        <ChatHistoryDialog
            where={isUsingInKb? 'kb': 'chat'}
            visible={isChatHistoryDialogOpen}
            onDialogSwitch={(dialogId: number, robotId: string) => {
              setDialogId(dialogId)
              const userFound = users.find((u: ChatUser) => u.robotId == robotId)
              if(userFound) {
                setSelectedUser(userFound)
              }
            }}
            onHide={() => {
              setIsChatHistoryDialogOpen(false)
            }} />


      </div>
  );
});
export default ChatArea;
