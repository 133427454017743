import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const PptOutlineApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("title", "PPT标题", "请输入PPT标题,例如: 新能源汽车发展趋势", "PPT标题"),
        new WidgetAppParamTextArea("outline", "PPT大纲", "请输入PPT大纲,例如:\n1.背景介绍2.新能源汽车现状\n3.动力电池技术", "PPT大纲"),
    ];

    const template = "PptOutline";
    const title = "PPT大纲生成";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default PptOutlineApp;