// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-chat-root {
  flex-direction: column;
  height: calc(100vh - var(--ct-topbar-height) - var(--ct-footer-height));
}`, "",{"version":3,"sources":["webpack://./src/pages/apps/AiChat/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uEAAuE;AACzE","sourcesContent":[".ai-chat-root {\r\n  flex-direction: column;\r\n  height: calc(100vh - var(--ct-topbar-height) - var(--ct-footer-height));\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
