import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Dropdown, ButtonGroup, Form} from "react-bootstrap";
import "@fullcalendar/react";

// components

import Select from "react-select";
import Conf from "../Utils/Conf";
import Spinner from "../../../components/Spinner";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import useT2tt from "../../../utils/t2tt";
import "./super_trans.css"
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import {ModelSelector} from "../AiChat/ModelSelector";
import {ModelConfig} from "../../../utils/WebAppConfig";
import {HotLibSelect} from "../../../utils/HotLibSelect";
import {APICore} from "../../../helpers/api/apiCore";
import {set} from "react-hook-form";
import jschardet from 'jschardet';


interface OutputItem {
    line: number
    fromLine: string
    toLine: string
    isUsed: boolean
}
interface DiffControlProps extends OutputItem {
    showFrom: boolean
}

interface LangInfo {
    value: string
    label: string
}

interface Engine {
    value: string
    label: string
}



const DiffControl = (props: DiffControlProps) => {
    return (
        <div>
            {props.showFrom && (
                <div>
                    {props.fromLine}
                </div>
            )}

            <div className={"text-success"}>
                {props.toLine}
            </div>
            <p></p>
        </div>
    )
}

const defaultEngine = {
    value: 'llm',
    label: 'LLM大模型'
}
let ws: WebSocket | null = null

const SuperTransApp = () => {
    const [currentEngine, setCurrentEngine] = useState<Engine>(defaultEngine);
    const [enableHot, setEnableHot] = useState<boolean>(false);
    const [realtimeMode, setRealtimeMode] = useState<boolean>(false);
    const [dictMode, setDictMode] = useState<boolean>(false);
    const [transDiff, setTransDiff] = useState<boolean>(false);
    const [summaryMode, setSummaryMode] = useState<boolean>(false);
    const [fromLength, setFromLength] = useState<number>(0);
    const [modelConfig, setModelConfig] = useState<ModelConfig | null>(null);
    const [transTimestamp, setTransTimestamp] = useState(0);
    const transTimestampRef = useRef(0);  // 添加 ref
    const [hotId, setHotId] = useState(-1)
    const [diffItems, setDiffItems] = useState<OutputItem[]>([])
    const [progress, setProgress] = useState(0);

    let trans_id: any
    const t2tt = useT2tt()

    const [fromText, setFromText] = useState("");
    const [toText, setToText] = useState<string>("");

    const [fromLang, setFromLang] = useState<LangInfo | null>(null);
    const [toLang, setToLang] = useState<LangInfo | null>(null);


    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)

    }

    const handleImportClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "text/plain,text/markdown,text/csv,text/html,text/xml,text/css,text/javascript";
        input.style.display = "none";
        input.addEventListener("change", (event: any) => {
            const file = event.target.files[0];
            if (file) {
                // 先用 ArrayBuffer 读取文件内容
                const reader = new FileReader();
                reader.onload = async (e) => {
                    if (e.target?.result) {
                        // 将 ArrayBuffer 转换为字符串
                        const buffer = e.target.result as ArrayBuffer;
                        const uint8Array = new Uint8Array(buffer);
                        const binary = Array.from(uint8Array)
                            .map(byte => String.fromCharCode(byte))
                            .join('');

                        // 检测编码
                        const detector = jschardet.detect(binary);
                        const encoding = detector.encoding || 'UTF-8'; // 默认使用 UTF-8

                        // 使用检测到的编码重新读取文件
                        const textReader = new FileReader();
                        textReader.onload = async (e) => {
                            if (e.target?.result) {
                                const text = e.target.result;
                                setFromText('' + text);
                                setFromLength(('' + text).length);
                                if (realtimeMode) {
                                    await tryTranslate('' + text);
                                }
                            }
                        };
                        textReader.readAsText(file, encoding);
                    }
                };
                reader.readAsArrayBuffer(file);
            }
        });
        input.click();
    };

    const handleStartTransClick = async () => {
        await tryTranslate(fromText)
    }
    const handleStopTransClick = () => {
        setTransTimestamp(0);
        setToText('');
        setDiffItems([]);
        setIsBusy(false)
        console.log("准备关闭连接")
        if(ws) {
            console.log("强行关闭连接")
            ws.close()
            ws = null
        }
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(toText)
            .then(() => {
                alert('内容已复制到剪贴板!');
            })
            .catch((err) => {
                console.error('无法复制到剪贴板:', err);
            });
    };
    const handleDownloadFile = () => {
        const blob = new Blob([toText], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'content.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const tryTranslate = async (text: string) => {
        if(trans_id) {
            clearTimeout(trans_id)
        }
        setToText('')
        setProgress(0)
        const fromLines = text.split("\n")
        setDiffItems(fromLines.map((v, k) => ({
            line: k,
            fromLine: v,
            toLine: '',
            isUsed: false
        })))
        const api =  new APICore()
        if(!api.isUserAuthenticated()) {
            console.log("用户未登录")
            return;
        }
        if(!api.getLoggedInUser()) {
            console.log("用户未登录")
            return;
        }


        trans_id = setTimeout(async ()=> {
            const timestamp = new Date().getTime();
            setTransTimestamp(timestamp);

            const dataToPost = {
                'hot_id': hotId,
                'model_name': modelConfig?.modelName,
                'from_lang': fromLang!.value,
                'to_lang': toLang!.value,
                'from_text': text,
                'engine': currentEngine.value,
                'summary': summaryMode,
                'trans_timestamp': timestamp,
                'merge': !transDiff,
                'merge_max': 1024,
                'Authorization': "Bearer " + api.getLoggedInUser().token
            }

            setIsBusy(true)
            if(text == '') {
                setToText('');
                setDiffItems([])
                setIsBusy(false)
            } else {
                try {
                    if(ws) {
                        ws.close()
                    }
                    ws = new WebSocket(Conf.urlTransTranslateTextWs);
                    ws.onopen = () => {ws!.send(JSON.stringify(dataToPost))}
                    ws.onerror = (err) => console.log(err)
                    ws.onclose = () => {
                        setIsBusy(false)
                    }
                    ws.onmessage = (e) => {
                        const data = JSON.parse(e.data) as any
                        console.log(data)
                        if(data.event == 'token') {
                            setToText(prevText => prevText + data.data)  // 使用函数式更新
                            const blockId = data.block_id
                            const blockTotal = data.block_total
                            if(blockTotal == 0) {
                                setProgress(0)
                            } else {
                                setProgress(blockId / blockTotal * 100)
                            }
                            if(!summaryMode && data.data != "\n") {
                                setDiffItems(prevItems => {
                                    prevItems[data.block_id].toLine =prevItems[data.block_id].toLine + data.data
                                    prevItems[data.block_id].isUsed = true
                                    return [...prevItems]
                                })
                            }
                        }
                    }

                    /*
                    const data = await auth_fetch_post_json(Conf.urlTransTranslateText, dataToPost) as any;
                    console.log("Current transTimestamp:", transTimestampRef.current, "Response data:", data);
                    if(data.trans_timestamp == transTimestampRef.current) {
                        setToText(data.to_text);
                        setDiff(data.diff);
                        setIsBusy(false)
                    }
                     */
                } catch (error) {
                    console.error("Translation error:", error);
                    setIsBusy(false);
                }
            }

        }, 500)
    }

    const onSourceTextChanged = async (e: any) => {
        console.log("onSourceTextChanged", e.target.value);
        setFromText(e.target.value)
        setFromLength(e.target.value.length)
        if(realtimeMode) {
            await tryTranslate(e.target.value)
        }
    }


    useEffect(() => {
                if(t2tt.t2ttInfo) {
                    setTimeout(() => {
                        setFromLang(t2tt.t2ttInfo['engines_info']['llm']['default_src'])
                        setToLang(t2tt.t2ttInfo['engines_info']['llm']['default_tgt'])
                    }, 1000);

                }
    }, [t2tt.t2ttInfo]);

    useEffect(() => {
        if(!modelConfig) {
            setCurrentEngine(defaultEngine)
            return
        }
        if(modelConfig.modelName!='nllb') {
            setCurrentEngine(defaultEngine)
        } else {
            setCurrentEngine({
                value: 'nllb',
                label: 'nllb'
            })
        }
    }, [modelConfig]);



    useEffect(() => {
        if(currentEngine && t2tt.t2ttInfo) {
            const defautl_src = t2tt.t2ttInfo['engines_info'][currentEngine.value]['default_src']
            const default_tgt = t2tt.t2ttInfo['engines_info'][currentEngine.value]['default_tgt']
            console.log(currentEngine.value)

            console.log(defautl_src)
            console.log(default_tgt)
            setFromLang(defautl_src)
            setToLang(default_tgt)
        }
    }, [currentEngine, t2tt.t2ttInfo]);


    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('文本翻译'))
    }, []);

    useEffect(() => {
        console.log("transTimestamp " + transTimestamp);
        transTimestampRef.current = transTimestamp;  // 更新 ref
    }, [transTimestamp]);

    return (
        <div className={'super-trans-root'}>
            <div className={'toolbar'}>
                <div>
                    <ModelSelector
                        disabled={isBusy}
                        mode={'trans'}
                        onModelChanged={model => setModelConfig(model)}
                    />

                </div>
                <Select
                    isDisabled={isBusy}
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    isSearchable={true}
                    value={fromLang}

                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '200px',
                        }),
                    }}
                    options={
                        t2tt.t2ttInfo ?
                            t2tt.t2ttInfo[currentEngine.value]['src_codes'].map((code: any) => ({
                                value: code,
                                label: t2tt.t2ttInfo[currentEngine.value]['src_langs'][code]['language']
                            }))
                            : []
                    }
                    onChange={(e) => {
                        setFromLang(e)
                    }}
                ></Select>

                <Button disabled={isBusy} onClick={() => handleSwitchLang()} variant="default"
                        className="btn-light waves-effect waves-light">
                    <i className="fas fa-exchange-alt"></i>
                </Button>
                <Select
                    isDisabled={isBusy}
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    isSearchable={true}
                    value={toLang}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '200px',
                        }),
                    }}
                    options={
                        t2tt.t2ttInfo ?
                            t2tt.t2ttInfo[currentEngine.value]['tgt_codes'].map((code: any) => ({
                                value: code,
                                label: t2tt.t2ttInfo[currentEngine.value]['tgt_langs'][code]['language']
                            }))
                            : []
                    }
                    onChange={(e) => {
                        setToLang(e)
                    }}
                ></Select>
                <HotLibSelect onHotLibSelected={setHotId} isDisabled={isBusy}></HotLibSelect>

                <Form.Check
                    disabled={isBusy}
                    type="checkbox"
                    label={"实时翻译"}
                    checked={realtimeMode}
                    onChange={e => setRealtimeMode(e.target.checked)}
                />
                <Form.Check
                    disabled={isBusy}
                    type="checkbox"
                    label={"双语对照"}
                    checked={transDiff}
                    onChange={e => setTransDiff(e.target.checked)}
                />


                <Form.Check
                    disabled={isBusy}
                    type="checkbox"
                    label={"总结模式"}
                    checked={summaryMode}
                    onChange={e => setSummaryMode(e.target.checked)}
                />

                {/**
                 <Button variant={'link'} className={"button-more"}><i className={"mdi mdi-cog"}></i></Button>
                 **/}



            </div>

            <div className={'trans-area'}>
                <div className={'trans-left'}>
                    <textarea
                        className={'trans-input'}
                        placeholder={"输入翻译内容"}
                        value={fromText}
                        onChange={onSourceTextChanged}
                    />
                    <div style={{
                        position: 'relative',
                        bottom: '0px',
                        left: '0px',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: '2rem',
                        paddingLeft: '2rem'


                    }}>
                        <button disabled={isBusy} onClick={() => handleImportClick()}
                                className="btn btn-light"><i
                            className={"far fa-folder-open me-1"}></i>导入文本文件
                        </button>

                        {isBusy && <span className={"text-info"}><Spinner className="spinner-border-sm"/>正在翻译({progress.toFixed(2)}%)</span>}
                        {!isBusy && <span className={"text-info text-muted"}>字符数 {fromLength}</span>}

                        {
                            !realtimeMode && !isBusy && (<button onClick={() => handleStartTransClick()}
                                                                 className="btn btn-light">开始翻译</button>)
                        }
                        {
                            !realtimeMode && isBusy && (
                                <button onClick={() => handleStopTransClick()}
                                        className="btn btn-danger">停止翻译</button>
                            )
                        }

                    </div>
                </div>
                <div className={'trans-right'}>
                    <div className={'trans-output'}
                         key={'to_text_div'}
                    >
                        {diffItems.filter(x => x.isUsed).map((v, k) => (
                            <DiffControl
                                key={'diff_control_' + v.line}
                                line={k}
                                fromLine={v.fromLine}
                                isUsed={v.isUsed}
                                showFrom={transDiff}
                                toLine={v.toLine}/>))}

                    </div>
                    <div style={{
                        position: 'relative',
                        bottom: '0px',
                        left: '0px',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: '2rem',
                        paddingLeft: '2rem'
                    }}>
                        <button disabled={isBusy} className="btn btn-light me-2"
                                onClick={handleDownloadFile}>
                            <i className={'far fa-save me-1'}></i>保存结果
                        </button>
                        <button disabled={isBusy} className="btn btn-light"
                                onClick={handleCopyToClipboard}>
                            <i className={'far fa-copy me-1'}></i>复制内容
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SuperTransApp;
