import './RobotSelector.css'
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import React, { useState } from "react";
import { ChatUser, users } from "./data";

export interface OptionType {
    value: string;
    label: string;
    user: ChatUser;
}

const Option: React.FC<OptionProps<OptionType, false>> = (props) => (
    <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={props.data.user.avatar}
                alt={props.data.label}
                style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px' }}
            />
            {props.data.label}
        </div>
    </components.Option>
);

const SingleValue: React.FC<SingleValueProps<OptionType>> = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={props.data.user.avatar}
                alt={props.data.label}
                style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px' }}
            />
            {children}
        </div>
    </components.SingleValue>
);
interface RobotSelectorProps {
    value: OptionType;
    onChange: (value: OptionType) => void
}
export const RobotSelector = (props: RobotSelectorProps) => {

    const options: OptionType[] = users.map(user => ({
        value: user.robotId || '',
        label: user.name || '',
        user: user
    }));

    return (
        <div className={'robot-selector'} style={{ width: '100%' }}>
            <Select<OptionType>
                className="react-select react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                isSearchable={false}
                value={props.value}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: '100%', // 将宽度设置为100%
                    }),
                    container: (provided) => ({
                        ...provided,
                        width: '100%', // 确保容器也是100%宽度
                    }),
                }}
                options={options}
                onChange={(newValue) => {
                    if (newValue) {
                        props.onChange(newValue)
                    }
                }}
                components={{ Option, SingleValue }}
            />
        </div>
    )
}