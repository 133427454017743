// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background-color: #eeeeee;
    padding: 1rem;
    margin-bottom: 1rem;
}


`, "",{"version":3,"sources":["webpack://./src/pages/apps/VideoTransOnce/VideoTransOnce.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".tool-bar {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n    gap: 10px;\r\n    background-color: #eeeeee;\r\n    padding: 1rem;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
