import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import AiToolApp from "../pages/apps/AiTool";
// WIDGET_APP_IMPORT_START

import PsychologyWikiApp from "../pages/apps/AiTool/widgets/psychologywiki/PsychologyWikiApp";

import EngChatApp from "../pages/apps/AiTool/widgets/engchat/EngChatApp";

import FlowChartApp from "../pages/apps/AiTool/widgets/flowchart/FlowChartApp";

import StudyMindMapApp from "../pages/apps/AiTool/widgets/studymindmap/StudyMindMapApp";

import RulesWriteApp from "../pages/apps/AiTool/widgets/ruleswrite/RulesWriteApp";

import StudyTestApp from "../pages/apps/AiTool/widgets/studytest/StudyTestApp";


import CreateHelperApp from "../pages/apps/AiTool/widgets/createhelper/CreateHelperApp";

import SwotApp from "../pages/apps/AiTool/widgets/swot/SwotApp";

import ResearchReportApp from "../pages/apps/AiTool/widgets/researchreport/ResearchReportApp";

import ActivityPlanApp from "../pages/apps/AiTool/widgets/activityplan/ActivityPlanApp";

import MeetingMinutesApp from "../pages/apps/AiTool/widgets/meetingminutes/MeetingMinutesApp";

import MindMapApp from "../pages/apps/AiTool/widgets/mindmap/MindMapApp";

import PoetryToolApp from "../pages/apps/AiTool/widgets/poetrytool/PoetryToolApp";
import NetSecToolApp from "../pages/apps/AiTool/widgets/netsectool/NetSecToolApp";
import PptOutlineApp from "../pages/apps/AiTool/widgets/pptoutline/PptOutlineApp";
import SuperTransApp from "../pages/apps/SuperTrans";
import AiChatApp from "../pages/apps/AiChat";
import InpaintApp from "../pages/apps/Inpaint";
import DownloadCenterApp from "../pages/apps/DownloadCenter";
import KnowImageApp from "../pages/apps/KnowImage";
import ChatFileApp from "../pages/apps/ChatFile";
import TextToImageApp from "../pages/apps/TextToImage";
import ImageTransApp from "../pages/apps/ImageTrans";
import OcrApp from "../pages/apps/Ocr";
import AsrOfflineApp from "../pages/apps/AsrOffline";
import UsersApp from "../pages/apps/Users";
import DocBaseApp from "../pages/apps/DocBase";
import DeployApp from "../pages/apps/Deploy";
import MyProfileApp from "../pages/apps/MyProfile";
import OpApproveApp from "../pages/apps/OpApprove";
import OpLogsApp from "../pages/apps/OpLogs";
import UserLogsApp from "../pages/apps/UserLogs";
import {APICore} from "../helpers/api/apiCore";
import NetLogsApp from "../pages/apps/NetLogs";
import DiskUsageApp from "../pages/apps/DiskUsage";
import WelcomeApp from "../pages/apps/Welcome";
import PromptDemoApp from "../pages/apps/PromptDemos";
import WelcomeEditorApp from "../pages/apps/WelcomeEditor";
import TtsApp from "../pages/apps/Tts";
import StyleEditorApp from "../pages/apps/StyleEditor";
import KbChatApp from "../pages/apps/KbChat";
import KbAdminApp from "../pages/apps/KbAdmin";
import OcrBatchApp from "../pages/apps/OcrBatch";
import DocBatchApp from "../pages/apps/DocBatch";
import VideoTransOnceApp from "../pages/apps/VideoTransOnce";
import AudioTransOnceApp from "../pages/apps/AudioTransOnce";
import HootToolApp from "../pages/apps/HotTool";
import AiReaderApp from "../pages/apps/AiReader";
import {AiNewsApp} from "../pages/apps/AiNews";


// WIDGET_APP_IMPORT_END

// import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const LoginAdmin = React.lazy(() => import("../pages/auth_admin/LoginAdmin"));
const LoginAudit = React.lazy(() => import("../pages/auth_audit/LoginAudit"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));


const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));

// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));
// - other
// uikit
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Portlets = React.lazy(() => import("../pages/uikit/Portlets"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const TooltipsPopovers = React.lazy(
  () => import("../pages/uikit/TooltipsPopovers")
);
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Animation = React.lazy(() => import("../pages/uikit/Animation"));
const TourPage = React.lazy(() => import("../pages/uikit/TourPage"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));
const LoadingButtons = React.lazy(
  () => import("../pages/uikit/LoadingButtons")
);

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// icons
const TwoToneIcons = React.lazy(() => import("../pages/icons/TwoToneIcons/"));
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons/"));
const Dripicons = React.lazy(() => import("../pages/icons/Dripicons/"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons/"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons/")
);
const ThemifyIcons = React.lazy(() => import("../pages/icons/ThemifyIcons/"));
const SimpleLineIcons = React.lazy(
  () => import("../pages/icons/SimpleLineIcons/")
);
const WeatherIcons = React.lazy(() => import("../pages/icons/WeatherIcons/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));


export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
// const rootRoute: RoutesProps = {
//     path: '/',
//     exact: true,
//     element: () => <Root />,
//     route: Route,
// };

const is_admin = () => {
  const api = new APICore()
  const res = api.isUserAuthenticated() && api.getLoggedInUser() && api.getLoggedInUser()['role'] == "Admin";
  return res

}
const is_audit = () => {
  const api = new APICore()
  const res = api.isUserAuthenticated() && api.getLoggedInUser() && api.getLoggedInUser()['role'] == "Audit";
  return res
}
// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: is_admin()? (<Navigate to="/apps/welcome" />) : is_audit()? (<Navigate to="/apps/welcome" />) : (<Navigate to="/apps/welcome" />) ,
      route: PrivateRoute,
    },
  ],
};

const aitoolAppRoutes: RoutesProps = {
  path: "/apps/aitool",
  name: "AiTool",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <AiToolApp />,
  header: "Apps",
};

const supertransAppRoutes: RoutesProps = {
  path: "/apps/supertrans",
  name: "SuperTrans",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <SuperTransApp />,
  header: "Apps",
};

const ocrAppRoutes: RoutesProps = {
  path: "/apps/ocr",
  name: "Ocr",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <OcrApp />,
  header: "Apps",
};

const ocrBatchAppRoutes: RoutesProps = {
  path: "/apps/ocrbatch",
  name: "OcrBatch",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <OcrBatchApp />,
  header: "Apps",
};


const imagetransAppRoutes: RoutesProps = {
  path: "/apps/imagetrans",
  name: "ImageTrans",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <ImageTransApp />,
  header: "Apps",
};

const aireaderAppRoutes: RoutesProps = {
  path: "/apps/aireader",
  name: "AiReader",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <AiReaderApp />,
  header: "Apps",
};

const ainewsAppRoutes: RoutesProps = {
  path: "/apps/ainews",
  name: "AiNews",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <AiNewsApp />,
  header: "Apps",
};

const audiotransonceAppRoutes: RoutesProps = {
  path: "/apps/audiotransonce",
  name: "AudioTransOnce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "codepen",
  element: <AudioTransOnceApp />,
  header: "Apps",
};


const videotransonceAppRoutes: RoutesProps = {
  path: "/apps/videotransonce",
  name: "VideoTransOnce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "codepen",
  element: <VideoTransOnceApp />,
  header: "Apps",
};

const docbatchAppRoutes: RoutesProps = {
  path: "/apps/docbatch",
  name: "DocBatch",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <DocBatchApp />,
  header: "Apps",
};


const aichatAppRoutes: RoutesProps = {
  path: "/apps/aichat",
  name: "AiChat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <AiChatApp />,
  header: "Apps",
};

const chatFileAppRoutes: RoutesProps = {
  path: "/apps/chatfile",
  name: "ChatFile",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <ChatFileApp />,
  header: "Apps",
};

const kbChatAppRoutes: RoutesProps = {
  path: "/apps/kbchat",
  name: "KbChat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <KbChatApp />,
  header: "Apps",
};

const inpaintAppRoutes: RoutesProps = {
  path: "/apps/inpaint",
  name: "Inpaint",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <InpaintApp />,
  header: "Apps",
};

const downloadcenterAppRoutes: RoutesProps = {
  path: "/apps/downloadcenter",
  name: "DownloadCenter",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <DownloadCenterApp />,
  header: "Apps",
};

const knowimageAppRoutes: RoutesProps = {
  path: "/apps/knowimage",
  name: "KnowImage",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <KnowImageApp />,
  header: "Apps",
};

const texttoimageAppRoutes: RoutesProps = {
  path: "/apps/texttoimage",
  name: "TextToImage",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <TextToImageApp />,
  header: "Apps",
};


const asrofflineAppRoutes: RoutesProps = {
  path: "/apps/asroffline",
  name: "AsrOffline",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "speaker",
  element: <AsrOfflineApp />,
  header: "Apps",
};

const ttsAppRoutes: RoutesProps = {
  path: "/apps/tts",
  name: "Tts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "radio",
  element: <TtsApp />,
  header: "Apps",
};

const usersAppRoutes: RoutesProps = {
  path: "/apps/users",
  name: "Users",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <UsersApp />,
  header: "Apps",
};

const docbaseAppRoutes: RoutesProps = {
  path: "/apps/docbase",
  name: "DocBase",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "command",
  element: <DocBaseApp />,
  header: "Apps",
};


const kbadminAppRoutes: RoutesProps = {
  path: "/apps/kbadmin",
  name: "KbAdmin",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "command",
  element: <KbAdminApp />,
  header: "Apps",
};


const promptDemoAppRoutes: RoutesProps = {
  path: "/apps/promptdemo",
  name: "PromptDemo",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mdi mdi-frequently-asked-questions",
  element: <PromptDemoApp />,
  header: "Apps",
};

const welcomeEditorAppRoutes: RoutesProps = {
  path: "/apps/welcomeeditor",
  name: "WelcomeEditor",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "meh",
  element: <WelcomeEditorApp />,
  header: "Apps",
};

const hotToolAppRoutes: RoutesProps = {
  path: "/apps/hottool",
  name: "HotTool",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "meh",
  element: <HootToolApp />,
  header: "Apps",
};


const styleEditorAppRoutes: RoutesProps = {
  path: "/apps/styleeditor",
  name: "StyleEditor",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "figma",
  element: <StyleEditorApp />,
  header: "Apps",
};


const deployAppRoutes: RoutesProps = {
  path: "/apps/deploy",
  name: "Deploy",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <DeployApp />,
  header: "Apps",
};

const myProfileAppRoutes: RoutesProps = {
  path: "/apps/myProfile",
  name: "MyProfile",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <MyProfileApp />,
  header: "Apps",
};


const opapproveAppRoutes: RoutesProps = {
  path: "/apps/opapprove",
  name: "OpApprove",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <OpApproveApp />,
  header: "Apps",
};

const oplogsAppRoutes: RoutesProps = {
  path: "/apps/oplogs",
  name: "OpLogs",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <OpLogsApp />,
  header: "Apps",
};

const userlogsAppRoutes: RoutesProps = {
  path: "/apps/userlogs",
  name: "UserLogs",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <UserLogsApp />,
  header: "Apps",
};

const netlogsAppRoutes: RoutesProps = {
  path: "/apps/netlogs",
  name: "NetLogs",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <NetLogsApp />,
  header: "Apps",
};


const diskusageAppRoutes: RoutesProps = {
  path: "/apps/diskusage",
  name: "DiskUsage",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "zap",
  element: <DiskUsageApp />,
  header: "Apps",
};

// WIDGET_APP_ROUTES_START

const PsychologyWikiAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/psychologywiki/psychologywikiapp",
  name: "PsychologyWiki",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <PsychologyWikiApp />,
  header: "Apps",
};

const EngChatAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/engchat/engchatapp",
  name: "EngChat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <EngChatApp />,
  header: "Apps",
};

const welcomeAppRoutes: RoutesProps = {
  path: "/apps/welcome",
  name: "Welcome",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <WelcomeApp />,
  header: "Apps",
};

const FlowChartAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/flowchart/flowchartapp",
  name: "FlowChart",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <FlowChartApp />,
  header: "Apps",
};

const StudyMindMapAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/studymindmap/studymindmapapp",
  name: "StudyMindMap",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <StudyMindMapApp />,
  header: "Apps",
};

const RulesWriteAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/ruleswrite/ruleswriteapp",
  name: "RulesWrite",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <RulesWriteApp />,
  header: "Apps",
};

const StudyTestAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/studytest/studytestapp",
  name: "StudyTest",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <StudyTestApp />,
  header: "Apps",
};


const CreateHelperAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/createhelper/createhelperapp",
  name: "CreateHelper",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <CreateHelperApp />,
  header: "Apps",
};

const SwotAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/swot/swotapp",
  name: "Swot",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <SwotApp />,
  header: "Apps",
};

const ResearchReportAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/researchreport/researchreportapp",
  name: "ResearchReport",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <ResearchReportApp />,
  header: "Apps",
};

const ActivityPlanAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/activityplan/activityplanapp",
  name: "ActivityPlan",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <ActivityPlanApp />,
  header: "Apps",
};

const MeetingMinutesAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/meetingminutes/meetingminutesapp",
  name: "MeetingMinutes",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <MeetingMinutesApp />,
  header: "Apps",
};

const MindMapAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/mindmap/mindmapapp",
  name: "MindMap",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <MindMapApp />,
  header: "Apps",
};

const PoetryToolAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/poetrytool/poetrytoolapp",
  name: "PoetryTool",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <PoetryToolApp />,
  header: "Apps",
};

const NetSecToolAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/netsectool/netsectoolapp",
  name: "NetSecTool",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <NetSecToolApp />,
  header: "Apps",
};
const PptOutlineAppRoutes: RoutesProps = {
  path: "/apps/aitool/widgets/pptoutline/pptoutlineapp",
  name: "PptOutline",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "disc",
  element: <PptOutlineApp />,
  header: "Apps",
};
// WIDGET_APP_ROUTES_END


const chatAppRoutes = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  element: <ChatApp />,
};


const appRoutes = [
  welcomeAppRoutes,
  aitoolAppRoutes,
  supertransAppRoutes,
  ocrAppRoutes,
  ocrBatchAppRoutes,
  imagetransAppRoutes,
  aireaderAppRoutes,
  ainewsAppRoutes,
  audiotransonceAppRoutes,
  videotransonceAppRoutes,
  docbatchAppRoutes,
  aichatAppRoutes,
  chatFileAppRoutes,
  kbChatAppRoutes,
  inpaintAppRoutes,
  downloadcenterAppRoutes,
  knowimageAppRoutes,
  texttoimageAppRoutes,
  asrofflineAppRoutes,
  ttsAppRoutes,
  usersAppRoutes,
  docbaseAppRoutes,
  kbadminAppRoutes,
  promptDemoAppRoutes,
  welcomeEditorAppRoutes,
  hotToolAppRoutes,
  styleEditorAppRoutes,
  deployAppRoutes,
  myProfileAppRoutes,
    opapproveAppRoutes,
    oplogsAppRoutes,
  userlogsAppRoutes,
  netlogsAppRoutes,
  diskusageAppRoutes,


  PoetryToolAppRoutes,

  MindMapAppRoutes,

  MeetingMinutesAppRoutes,

  ActivityPlanAppRoutes,

  ResearchReportAppRoutes,

  SwotAppRoutes,

  CreateHelperAppRoutes,

  StudyTestAppRoutes,

  RulesWriteAppRoutes,

  StudyMindMapAppRoutes,

  FlowChartAppRoutes,

  EngChatAppRoutes,


  PsychologyWikiAppRoutes,

  // APP_ROUTE_ITEM_START

  NetSecToolAppRoutes,
  PptOutlineAppRoutes,
  // APP_ROUTE_ITEM_END
  chatAppRoutes,
];

// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    
    
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes = {
  path: "/ui",
  name: "Components",
  icon: "pocket",
  header: "UI Elements",
  children: [
    {
      path: "/ui/base",
      name: "Base UI",
      children: [
        {
          path: "/ui/buttons",
          name: "Buttons",
          element: <Buttons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/cards",
          name: "Cards",
          element: <Cards />,
          route: PrivateRoute,
        },
        {
          path: "/ui/avatars",
          name: "Avatars",
          element: <Avatars />,
          route: PrivateRoute,
        },
        {
          path: "/ui/portlets",
          name: "Portlets",
          element: <Portlets />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tabs-accordions",
          name: "Tabs & Accordions",
          element: <TabsAccordions />,
          route: PrivateRoute,
        },
        {
          path: "/ui/progress",
          name: "Progress",
          element: <Progress />,
          route: PrivateRoute,
        },
        {
          path: "/ui/modals",
          name: "Modals",
          element: <Modals />,
          route: PrivateRoute,
        },
        {
          path: "/ui/notifications",
          name: "Notifications",
          element: <Notifications />,
          route: PrivateRoute,
        },
        {
          path: "/ui/offcanvas",
          name: "Offcanvas",
          element: <Offcanvases />,
          route: PrivateRoute,
        },
        {
          path: "/ui/placeholders",
          name: "Placeholders",
          element: <Placeholders />,
          route: PrivateRoute,
        },
        {
          path: "/ui/spinners",
          name: "Spinners",
          element: <Spinners />,
          route: PrivateRoute,
        },
        {
          path: "/ui/images",
          name: "Images",
          element: <Images />,
          route: PrivateRoute,
        },
        {
          path: "/ui/carousel",
          name: "Carousel",
          element: <Carousels />,
          route: PrivateRoute,
        },
        {
          path: "/ui/listgroups",
          name: "List Groups",
          element: <ListGroups />,
          route: PrivateRoute,
        },
        {
          path: "/ui/embedvideo",
          name: "EmbedVideo",
          element: <EmbedVideo />,
          route: PrivateRoute,
        },
        {
          path: "/ui/dropdowns",
          name: "Dropdowns",
          element: <Dropdowns />,
          route: PrivateRoute,
        },
        {
          path: "/ui/ribbons",
          name: "Ribbons",
          element: <Ribbons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tooltips-popovers",
          name: "Tooltips & Popovers",
          element: <TooltipsPopovers />,
          route: PrivateRoute,
        },
        {
          path: "/ui/typography",
          name: "Typography",
          element: <Typography />,
          route: PrivateRoute,
        },
        {
          path: "/ui/grid",
          name: "Grid",
          element: <Grid />,
          route: PrivateRoute,
        }
      ],
    },
    {
      path: "/ui/extended",
      name: "Extended UI",
      children: [
        {
          path: "/extended-ui/nestable",
          name: "Nestable List",
          element: <NestableList />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/dragdrop",
          name: "Drag and Drop",
          element: <DragDrop />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/rangesliders",
          name: "Range Sliders",
          element: <RangeSliders />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/animation",
          name: "Animation",
          element: <Animation />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/sweet-alert",
          name: "Sweet Alert",
          element: <SweetAlerts />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/tour",
          name: "Tour Page",
          element: <TourPage />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/loading-buttons",
          name: "Loading Buttons",
          element: <LoadingButtons />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/two-tone",
          name: "Two Tone Icons",
          element: <TwoToneIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/feather",
          name: "Feather Icons",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/dripicons",
          name: "Dripicons",
          element: <Dripicons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/mdi",
          name: "Material Design",
          element: <MDIIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/font-awesome",
          name: "Font Awesome 5",
          element: <FontAwesomeIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/themify",
          name: "Themify",
          element: <ThemifyIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/simple-line",
          name: "Simple Line Icons",
          element: <SimpleLineIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/weather",
          name: "Weather Icons",
          element: <WeatherIcons />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/forms",
      name: "Forms",
      children: [
        {
          path: "/ui/forms/basic",
          name: "Basic Elements",
          element: <BasicForms />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/advanced",
          name: "Form Advanced",
          element: <FormAdvanced />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/validation",
          name: "Form Validation",
          element: <FormValidation />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/wizard",
          name: "Form Wizard",
          element: <FormWizard />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/upload",
          name: "File Upload",
          element: <FileUpload />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/editors",
          name: "Editors",
          element: <Editors />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/tables",
      name: "Tables",
      children: [
        {
          path: "/ui/tables/basic",
          name: "Basic",
          element: <BasicTables />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tables/advanced",
          name: "Advanced",
          element: <AdvancedTables />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/login_admin",
    name: "LoginAdmin",
    element: <LoginAdmin />,
    route: Route,
  },
  {
    path: "/auth/login_audit",
    name: "LoginAudit",
    element: <LoginAudit />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    element: <Login2 />,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    element: <Logout2 />,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    element: <Register2 />,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    element: <Confirm2 />,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    element: <SignInSignUp2 />,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    element: <LockScreen2 />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <Error404Two />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <Error500Two />,
    route: Route,
  },

];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
