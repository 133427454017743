import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const RulesWriteApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "主题", "请输入规章制度主题, 例如规范户外烧烤", "主题"),
        new WidgetAppParamTextArea("content", "大致内容", "请输入规章制度的大致内容", "大致内容"),
    ];

    const template = "RulesWrite";
    const title = "规章制度助手";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default RulesWriteApp;