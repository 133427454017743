export const sizePerPageList = [
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "50",
        value: 50,
    },
    {
        text: "100",
        value: 100,
    },
    {
        text: "200",
        value: 200,
    },
    {
        text: "500",
        value: 500,
    },
    {
        text: "1000",
        value: 1000,
    }
];