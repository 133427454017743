import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea } from "../WidgetAppParam";

const PoetryToolApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextArea("outline", "你想写一首什么样的诗?", "请输入诗词内容", "诗词内容"),
    ];

    const template = "PoetryTool";
    const title = "诗词创作";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default PoetryToolApp;