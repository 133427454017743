import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";

import ChatUsers from "./ChatUsers";
import ChatArea from "./ChatArea";
// dummy data
import { users, ChatUser } from "./data";
import {ChatTopBar} from "./ChatTopBar";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import './index.css'
// AiChatApp
const AiChatApp = () => {
    const [selectedUser, setSelectedUser] = useState<ChatUser>(users[0]);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('知识助手'))
    }, []);
    return (
        <div className={'ai-chat-root'}>
            <ChatArea isUsingInKb={false} selectedUser={selectedUser}/>
        </div>
    );
};

export default AiChatApp;