import React, {useState, useRef, useCallback, useEffect} from "react";
import { WidgetAppParam } from "./WidgetAppParam";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import Conf from "../../Utils/Conf";
import Spinner from "../../../../components/Spinner";
import { auth_fetch_post_json } from "../../../../utils/auth_fetch";
import { ModelSelector } from "../../AiChat/ModelSelector";
import { ModelConfig } from "../../../../utils/WebAppConfig";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../redux/store";
import {appTitleSet} from "../../../../redux/layout/actions";

import './BaseWidgetApp.css'
export interface BaseWidgetAppProps {
    avatar: string;
    template: string;
    title: string;
    params: () => WidgetAppParam[];
}

export const BaseWidgetApp: React.FC<BaseWidgetAppProps> = ({ avatar, template, title, params }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [modelConfig, setModelConfig] = useState<ModelConfig | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('AI工具箱: ' + title))
    }, []);
    const handleGenerate = useCallback(() => {
        console.log("handleGenerate " + formRef.current?.children.length);
        if(!modelConfig) {
            console.log("没有模型")
            return
        }
        if (formRef.current) {
            const dataToPost: any = {
                model_name: modelConfig!.modelName,
                template: template,
                params: []
            };
            const inputs = formRef.current.querySelectorAll("input");
            const textareas = formRef.current.querySelectorAll("textarea");
            const selects = formRef.current.querySelectorAll("select");
            console.log("inputs " + inputs.length);
            console.log("textareas " + textareas.length);
            console.log("selects " + selects.length);
            inputs.forEach((input) => {
                if (input.name) {
                    dataToPost.params.push({
                        name: input.name,
                        value: input.value,
                        desc: input.getAttribute('data-desc')
                    })
                }
            });
            textareas.forEach((textarea) => {
                if (textarea.name) {
                    dataToPost.params.push({
                        name: textarea.name,
                        value: textarea.value,
                        desc: textarea.getAttribute('data-desc')
                    })
                }
            });
            selects.forEach((select) => {
                if (select.name) {
                    dataToPost.params.push({
                        name: select.name,
                        value: select.value,
                        desc: select.getAttribute('data-desc')
                    })
                }
            })

            console.log(dataToPost)
            setIsBusy(true);
            auth_fetch_post_json(Conf.urlTextGenerate, dataToPost).then((data: any) => {
                console.log(data);
                const result = document.getElementById("result");
                if (result) {
                    if (data['type'] && data['type'] == 'ImageUrl') {
                        const imageUrl = data.text;
                        const imageElement = document.createElement("img");
                        imageElement.src = Conf.urlOfFile(imageUrl);
                        console.log(imageElement.src)
                        result.innerHTML = "";
                        result.appendChild(imageElement);
                    } else {
                        result.innerText = data.text;
                    }
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsBusy(false);
            })
        }
    }, [template, modelConfig]);

    return (

        <div className={'widget-app-container'}>
            <div className={"left-input-container"}>
                <form ref={formRef}>
                    {params().map((param, index) => (
                        <React.Fragment key={index}>
                            {param.createFormInput()}
                        </React.Fragment>
                    ))}
                </form>
                <div className="gap-2 left-tool-bar">
                    <ModelSelector mode={'chat'} onModelChanged={m => setModelConfig(m)}/>
                    <Button style={{marginLeft: '8px', width: '128px'}} variant="primary" onClick={handleGenerate}
                            disabled={isBusy}>
                                        <span hidden={!isBusy}>
                                            <Spinner
                                                className="spinner-border-sm me-1"
                                                color="white"
                                            />
                                        </span>
                        <span hidden={!isBusy}>生成中...</span>
                        <span hidden={isBusy}>生成</span>
                    </Button>
                </div>
            </div>
            <div className={"right-output-container"}>
                <div><strong>生成结果</strong></div>
                <div id="result" style={{whiteSpace: "pre-wrap", flex: 1, backgroundColor: '#ffffff'}}></div>
            </div>
        </div>
    );
};