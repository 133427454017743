// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-app-container {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    ;
}


.left-input-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px
}

.left-tool-bar {
    margin-top: auto;
}

.right-output-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px
}`, "",{"version":3,"sources":["webpack://./src/pages/apps/AiTool/widgets/BaseWidgetApp.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,SAAS;;AAEb;;;AAGA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,wCAAwC;IACxC;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,wCAAwC;IACxC;AACJ","sourcesContent":[".widget-app-container {\r\n    flex: 1;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 24px;\r\n    ;\r\n}\r\n\r\n\r\n.left-input-container {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 8px;\r\n    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);\r\n    padding: 8px\r\n}\r\n\r\n.left-tool-bar {\r\n    margin-top: auto;\r\n}\r\n\r\n.right-output-container {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 8px;\r\n    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);\r\n    padding: 8px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
