import { APP_MENU_SET_ACTIVE_ITEMS, AppMenuActionTypes } from "./types";

// 应用菜单状态管理
export interface AppMenuState {
    activeMenuItems: string[];
}

// 应用菜单初始状态
const appMenuState: AppMenuState = {
    activeMenuItems: [],
};

export const appMenuReducer = (state = appMenuState, action: AppMenuActionTypes): AppMenuState => {
    switch (action.type) {
        case APP_MENU_SET_ACTIVE_ITEMS:
            return {
                ...state,
                activeMenuItems: action.payload,
            };
        default:
            return state;
    }
};