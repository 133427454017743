import React, {useEffect} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { APICore } from "../helpers/api/apiCore";
import Conf from "../pages/apps/Utils/Conf";
import useUser from "../hooks/useUser";
import {logoutUser} from "../redux/auth/actions";
import {auth_fetch_post_json} from "../utils/auth_fetch";

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const api = new APICore();
  const dispatch = useDispatch();

  const checkUkey = () => {
    if(api.isUserAuthenticated() && api.getLoggedInUser() && api.getLoggedInUser()['login_method'] == "username") {
      return
    }

    if(api.isUserAuthenticated() && api.getLoggedInUser() && api.getLoggedInUser()['login_method'] == "goal") {
      auth_fetch_post_json(Conf.urlGoalAuthenticated, {})
          .then((resp_json: any) => {
            if(!resp_json["status"]) {
              dispatch(logoutUser() as any);
            }
          })
          .catch((err) => {
            console.log(err)
            dispatch(logoutUser() as any);
          })
    }


  }
  useEffect(() => {
    setInterval(()=> {
      checkUkey()
    }, 1000)
  }, []);


  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        console.log("use LAYOUT_HORIZONTAL")
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        console.log("use LAYOUT_DETACHED")
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        console.log("use LAYOUT_VERTICAL")
        break;
      default:
        console.log("use default layout")
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      // search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <Layout {...props}>{route.element}</Layout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
