import { useState, useCallback, useRef, useEffect } from 'react';
import {auth_fetch_post_json} from "./auth_fetch";
import Conf from "../pages/apps/Utils/Conf";


function useLogDownloader() {
    const [code, setCode] = useState('');
    const [url, setUrl] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [exporting, setExporting] = useState(false);





    const startExport = useCallback((logType) => {
        setExporting(true)
        setHasError(false)
        setCode("")
        setUrl("")
        auth_fetch_post_json(Conf.urlLogsExport + "/" + logType, {})
            .then((json: any) => {
                console.log(json)
                setUrl(json['url'])
                setCode(json['code'])
                setHasError(false)
                window.open(json["url"])
            })
            .catch(err => {
                setHasError(true)
            })


    }, []);




    return {
        code, url, fileSize,hasError, exporting,
        startExport,

    };
}

export default useLogDownloader;
