import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Alert, Badge} from "react-bootstrap";
import "@fullcalendar/react";


// components
import PageTitle from "../../../components/PageTitle";

import OcrBatch from "./OcrBatch";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Table from "../../../components/Table";
import {users} from "../AiChat/data";
import OcrImportModal from "./OcrImportModal";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import useOcr from "../../../utils/ocr";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";



const OcrBatchApp = () => {
    const [ocrTasks, setOcrTasks] = useState<any>([]);
    const ocrTable = useRef<any>(null);
    const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
    const ocr = useOcr()


    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: ocrTasks.length,
        },
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: "任务ID",
                accessor: "id",
                sort: false,
            },
            {
                Header: "文件名",
                accessor: "file_name",
                sort: false,
            },
            {
                Header: "语种",
                accessor: "lang_code_action",
                Cell: (data: any) => (
                    <span>{ocr.findOcrLang(data.row.original.lang_code)}</span>
                )
            },
            {
                Header: "提交时间",
                accessor: "timestr",
                sort: false,
            },
            {
                Header: "状态",
                accessor: "status_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (<Badge bg={"success"}>识别成功</Badge >)}
                        {data.row.original.status == "idle" && (<Badge bg={"info"}>正在排队</Badge>)}
                        {data.row.original.status == "processing" && (<Badge bg={"primary"}>正在识别</Badge>)}
                        {data.row.original.status == "error" && (<Badge bg={'danger'}>识别出错</Badge>)}
                    </div>
                )
            },
            {
                Header: "标注",
                accessor: "mark_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (
                            <a href={Conf.urlOfFile(data.row.original.mark_url)} target="_blank">下载</a>)}
                    </div>
                )
            },
            {
                Header: "翻译",
                accessor: "translated_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (data.row.original.translated_text_url !== "")  && (
                            <a href={Conf.urlOfFile(data.row.original.translated_text_url)} target="_blank">下载</a>)}
                    </div>
                )
            },
            {
                Header: "结果",
                accessor: "result_action",
                Cell: (data: any) => (
                    <div>
                        {data.row.original.status == "finished" && (
                            <a href={Conf.urlOfFile(data.row.original.text_url)} target="_blank">下载</a>)}
                    </div>
                )
            }
        ],
        []
    );

    const getTasks = useCallback(() => {
        console.log("getTasks")
        auth_fetch_post_json(Conf.urlSecAiOcrBatchTasksList, {
        })
            .then((data: any) => {
                setOcrTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const cleanTasks = useCallback(() => {
        auth_fetch_post_json(Conf.urlSecAiOcrBatchTasksClean, {
        })
            .then((data: any) => {
                setOcrTasks(data.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);




    useEffect(() => {

        const timer = setInterval(() => getTasks(), 1000)
        return () => {
            clearInterval(timer);
        };
    }, [getTasks]);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('OCR批量识别'))
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Button variant="outline-secondary" className="btn-sm me-2"
                            onClick={()=> setImportModalVisible(true)}
                    >
                       添加任务
                    </Button>
                    <Button variant="outline-danger" className="btn-sm" onClick={()=> cleanTasks()}>
                        清除任务
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md={12} xl={12}>
                    <Table
                        columns={columns}
                        data={ocrTasks}
                        pageSize={25}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        isSelectable={false}
                        ref={ocrTable}
                    />
                </Col>
            </Row>

            <OcrImportModal visible={importModalVisible} onHide={()=> setImportModalVisible(false)} onTasks={tasks => setOcrTasks(tasks)}></OcrImportModal>

        </>
    );
};

export default OcrBatchApp;
