import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea } from "../WidgetAppParam";

const EngChatApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextArea("points", "知识点", "输入你需要练习的知识点", "知识点"),
    ];

    const template = "EngChat";
    const title = "英语对话练习";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default EngChatApp;