// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-dlgs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;
    max-height: 240px;
    overflow-y: auto;

}`, "",{"version":3,"sources":["webpack://./src/pages/apps/AiChat/ChatHistoryDialog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;;AAEpB","sourcesContent":[".chat-dlgs-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 16px;\r\n    padding: 16px;\r\n    overflow-y: auto;\r\n    max-height: 240px;\r\n    overflow-y: auto;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
