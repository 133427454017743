import React from "react";
import {Col, Card} from "react-bootstrap";
import './BaseWidget.css'
export interface WidgetProps {
    pad?: string;
}

export abstract class BaseWidget<S, P extends WidgetProps> extends React.Component<S, P> {
    abstract title: string
    abstract desc: string
    abstract routePath: string
    abstract iconUrl: string
  render() {
      return (

          <a href={this.routePath}>

              <div className="base-widget-container">
                  <div className="avatar-container">
                      <img
                          src={this.iconUrl}
                          className="img-fluid rounded-circle avatar-image"
                          alt=""
                      />
                  </div>
                  <div className="title-desc-container">
                      <h5 className="mb-1 mt-2 font-16">{this.title}</h5>
                      <p className="mb-2 text-muted">{this.desc}</p>
                  </div>
              </div>

          </a>


      );
  }
}