export class FormatUtil {
    static formatProgress(progressValue: number) {
        if(!progressValue) return '0%'
        if(Number.isNaN(progressValue)) return '0%'
        else return `${(progressValue*100).toFixed(2)}%`;
    }

    static formatProgress2(currentValue: number, totalValue: number) {
        if(Number.isNaN(currentValue) || Number.isNaN(totalValue)) return '0%';
        if(totalValue == 0) return '0%';
        else return `${(currentValue/totalValue*100).toFixed(2)}%`;
    }

}