import { Alert, Button, ButtonGroup, Dropdown, Form, Modal, SplitButton } from "react-bootstrap";
import { FormProvider, set, useForm } from "react-hook-form";
import useUploadingMany, { UploadingManyUploadedItem } from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, { useCallback, useEffect, useState } from "react";
import Conf from "../Utils/Conf";
import Select from "react-select";

import { auth_fetch_post_json } from "../../../utils/auth_fetch";
import useDocTrans from "../../../utils/doctrans";
import useDocBatchClient, { TaskType, taskTypeFileInfos } from "../../../utils/docbatch_client";
import useAsr, { asrLlmMap, asrNllbMap } from "../../../utils/asr";
import { ModelSelector } from "../AiChat/ModelSelector";
import { ModelConfig } from "../../../utils/WebAppConfig";
import { HotLibSelect } from "../../../utils/HotLibSelect";
import { Engine } from "../../../utils/TransLangSelectGroup";
import useT2tt from "../../../utils/t2tt";
import { C } from "@fullcalendar/core/internal-common";
import useOcr, { ocrLlmMap, ocrNllbMap } from "../../../utils/ocr";

interface DocImportModalProps {
    visible: boolean
    onHide: () => void
    onTasks: (tasks: any) => void
}


interface LangInfo {
    value: string
    label: string
}

const DocImportModal = (props: DocImportModalProps) => {
    const defaultEngine = {
        value: 'llm',
        label: 'LLM大模型'
    }
    const methodsDocImport = useForm({});
    const docsUploader = useUploadingMany(Conf.urlSecaiFoSend)
    const [modelConfig, setModelConfig] = useState<ModelConfig | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [hotId, setHotId] = useState(-1)
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [enableSwitchLang, setEnableSwitchLang] = useState<boolean>(true)
    const docTrans = useDocTrans()
    const defaultFromLang = { "value": "en", "label": "英语" }
    const defaultToLang = { "value": "zh", "label": "中文" }
    const [currentEngine, setCurrentEngine] = useState<Engine>(defaultEngine);
    const [fromLang, setFromLang] = useState<LangInfo>(defaultFromLang);
    const [toLang, setToLang] = useState<LangInfo>(defaultToLang);
    const [fromLangs, setFromLangs] = useState<LangInfo[]>([]);
    const [toLangs, setToLangs] = useState<LangInfo[]>([]);
    const [selectedTaskType, setSelectedTaskType] = useState<TaskType>('txt_trans')
    const docBatchClient = useDocBatchClient()
    const asr = useAsr()
    const ocr = useOcr()
    const t2tt = useT2tt()

    useEffect(() => {
        if (!modelConfig) {
            setCurrentEngine(defaultEngine)
            return
        }
        if (modelConfig.modelName !== 'nllb') {
            setCurrentEngine(defaultEngine)
        } else {
            setCurrentEngine({
                value: 'nllb',
                label: 'nllb'
            })
        }
    }, [modelConfig]);

    useEffect(() => {
        // 检查是否可以切换语言,当from和to语言都在当前引擎支持的语言列表中时，可以切换
        if (fromLangs.length > 0 && toLangs.length > 0) {
            const canSwitch = fromLangs.some(lang => lang.value === toLang.value) && toLangs.some(lang => lang.value === fromLang.value);
            setEnableSwitchLang(canSwitch)
        }
    }, [fromLang, toLang, fromLangs, toLangs]);

    useEffect(() => {
        if (currentEngine && t2tt.t2ttInfo) {
            const defautl_src = t2tt.t2ttInfo['engines_info'][currentEngine.value]['default_src']
            const default_tgt = t2tt.t2ttInfo['engines_info'][currentEngine.value]['default_tgt']
            setFromLang(defautl_src)
            setToLang(default_tgt)
            console.log(currentEngine.value)
            console.log(t2tt.t2ttInfo)
            const srcLangsAll = t2tt.t2ttInfo[currentEngine.value]['src_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['src_langs'][code]['language']}))
            const tgtLangsAll = t2tt.t2ttInfo[currentEngine.value]['tgt_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['tgt_langs'][code]['language']}))
            if((selectedTaskType === 'video_trans' || selectedTaskType === 'audio_trans') && currentEngine.value === 'llm'){
                const srcLangs = srcLangsAll.filter((lang: any) => asr.getAsrLangCodesListForLlm().includes(lang.value))
                setFromLangs(srcLangs)
                console.log(srcLangs)
            } else if((selectedTaskType === 'video_trans' || selectedTaskType === 'audio_trans') && currentEngine.value === 'nllb') {
                const srcLangs = srcLangsAll.filter((lang: any) => asr.getAsrLangCodesListForNllb().includes(lang.value))
                setFromLangs(srcLangs)
                console.log(srcLangs)
            } else if((selectedTaskType === 'pdf_ocr_trans') && currentEngine.value === 'llm') {
                const srcLangs = srcLangsAll.filter((lang: any) => ocr.getOcrLangCodesListForLlm().includes(lang.value))
                setFromLangs(srcLangs)
                console.log(srcLangs)

            } else if((selectedTaskType === 'pdf_ocr_trans') && currentEngine.value === 'nllb') {
                console.log("switching" + ocr.getOcrLangCodesListForNllb())
                const srcLangs = srcLangsAll.filter((lang: any) => ocr.getOcrLangCodesListForNllb().includes(lang.value))
                setFromLangs(srcLangs)
                console.log(srcLangs)
            } else {
                setFromLangs(srcLangsAll)
                console.log(srcLangsAll)

            }
            setToLangs(tgtLangsAll)
        }
    }, [currentEngine, selectedTaskType, t2tt.t2ttInfo]);

    const getAcceptedExtensions = (taskType: TaskType) => {
        const acceptedExtensions = docBatchClient.getTaskTypeFileInfo(taskType).filters.flatMap(filter => filter.extensions.map(ext => `.${ext}`)).join(',');
        return acceptedExtensions

    }

    const handModelChanged = (model: ModelConfig) => {
        setModelConfig(model)
    }

    const addTask = async (values: any, event: any) => {
        console.log(values)
        console.log(event)
        setIsSaving(true)

        const json = {
            'base_model': modelConfig?.modelName,
            "asr_code": selectedTaskType === 'video_trans' || selectedTaskType === 'audio_trans' ? (currentEngine.value === 'llm' ? Object.keys(asrLlmMap).find(key => asrLlmMap[key] === fromLang.value) : Object.keys(asrNllbMap).find(key => asrNllbMap[key] === fromLang.value)) : '',
            "ocr_code": selectedTaskType === 'pdf_ocr_trans' ? (currentEngine.value === 'llm' ? Object.keys(ocrLlmMap).find(key => ocrLlmMap[key] === fromLang.value) : Object.keys(ocrNllbMap).find(key => ocrNllbMap[key] === fromLang.value)) : '',    
            "from_lang_code": fromLang.value,
            "to_lang_code": toLang.value,
            "from_lang_name": fromLang.label,
            "to_lang_name": toLang.label,
            "trans_engine": currentEngine.value,
            "trans_mode": "batch",
            "hot_id": hotId,
            "items": [
                ...docsUploader.uploadedItems!.map((item: UploadingManyUploadedItem) => {
                    return {
                        "file_name": item.fileName,
                        "file_id": item.fileId,
                        'task_type': selectedTaskType,
                    }
                })
            ]
        }


        auth_fetch_post_json(Conf.urlSecAiDocBatchTasksAdd, json)
            .then((data: any) => {
                console.log("tasksAdd:");

                console.log(data)
                props.onTasks(data.data)
                props.onHide()
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => {
                setIsSaving(false)
                docsUploader.clean()
            })
        return false
    }

    const handleSwitchLang = () => {
        const tmpLang = fromLang
        setFromLang(toLang)
        setToLang(tmpLang)
    }

    

    const uploadStatusIcon = (isUploading: boolean) => {
        if (isUploading) {
            return <Spinner className="spinner-border spinner-border-sm text-light ms-2" />
        } else {
            return <i className={"fe-trash-2 ms-2"}></i>
        }
    }

    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static" size={"xl"} centered
            keyboard={false}>
            <FormProvider {...methodsDocImport}>
                <Form onSubmit={methodsDocImport.handleSubmit(addTask)}>
                    <Modal.Header closeButton>
                        <h4 className="modal-title">批量添加文档翻译任务</h4>

                        <button type={"button"}
                            className={"btn btn-danger waves-effect waves-light ms-2 btn-sm"}
                            disabled={docsUploader.isUploading || isSaving}
                            hidden={docsUploader.uploadedItems?.length == 0 && !docsUploader.isUploading}
                            onClick={() => { docsUploader.clean() }}
                        >
                            <span>{docsUploader.statusText}</span>
                            <span>{uploadStatusIcon(docsUploader.isUploading)}</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-4">

                        <div className="col d-flex" style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }
                        }>
                            <div style={{ marginRight: "20px" }}>
                                <ModelSelector mode={'trans'} onModelChanged={model => { handModelChanged(model) }} />
                            </div>
                            <Select
                                className="react-select react-select-container me-2"
                                classNamePrefix="react-select"
                                options={fromLangs}
                                defaultValue={defaultFromLang}
                                value={fromLang}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '160px',
                                    }),
                                }}
                                onChange={x => {
                                    setFromLang(x ? x : defaultFromLang)
                                }}
                            ></Select>
                            <Button
                                disabled={!enableSwitchLang}
                                onClick={() => handleSwitchLang()}
                                variant="default"
                                className="btn-info waves-effect waves-light  me-2">
                                <i className="fas fa-exchange-alt"></i>
                            </Button>
                            <Select
                                className="react-select react-select-container me-3"
                                classNamePrefix="react-select"
                                options={toLangs}
                                value={toLang}
                                defaultValue={defaultToLang}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '160px',
                                    }),
                                }}
                                onChange={x => setToLang(x ? x : defaultToLang)}
                            ></Select>
                            <Dropdown
                                as={ButtonGroup}
                                onSelect={(e: any) => {
                                    if (e) {
                                        setSelectedTaskType(e)
                                    }
                                }}
                            >
                                <Button
                                    variant={'primary'}
                                    disabled={docsUploader.isUploading || isSaving}
                                    onClick={() => {
                                        docsUploader.startUpload(
                                            getAcceptedExtensions(selectedTaskType)
                                        )
                                    }}
                                >
                                    <i className={"icon-cloud-upload me-1"}></i>添加{docBatchClient.getTaskTypeFileInfo(selectedTaskType).name}
                                </Button>
                                <Dropdown.Toggle
                                    disabled={docsUploader.isUploading || isSaving}
                                    split variant={'primary'}>
                                    <i className="mdi mdi-chevron-down"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        taskTypeFileInfos.map(t => <Dropdown.Item key={t.type}
                                            eventKey={t.type}>{t.name}</Dropdown.Item>)
                                    }
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="add_help">帮助</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="mb-3"
                            style={{ maxHeight: "256px", overflowY: "auto" }}>
                            {docsUploader.uploadedItems?.map((item: UploadingManyUploadedItem) => {
                                return (
                                    <div key={item.fileId}><strong
                                        color={'secondary'}>{item.fileName}</strong>
                                    </div>
                                )
                            })}
                        </div>


                    </Modal.Body>

                    <Modal.Footer>
                        <HotLibSelect onHotLibSelected={setHotId} isDisabled={isBusy}></HotLibSelect>

                        <button
                            type="button"
                            className="btn btn-secondary waves-effect"
                            disabled={isSaving}
                            onClick={() => {
                                docsUploader.stopUpload()
                                props.onHide()
                            }}
                        >
                            取消
                        </button>
                        <button type="submit"
                            disabled={isSaving || docsUploader.uploadedItems?.length == 0}
                            className="btn btn-primary waves-effect waves-light">
                            {isSaving ? '正在保存' : '保存'}
                            <span hidden={!isSaving}><Spinner className="spinner-border-sm text-light ms-1" /></span>
                        </button>

                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}

export default DocImportModal;