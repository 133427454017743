import React, {useEffect, useRef, useState} from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";

// components
import PageTitle from "../../../components/PageTitle";
import "./VideoTransOnce.css"
import TransLangSelectGroup, {Engine, LangInfo} from "../../../utils/TransLangSelectGroup";
import UploadableVideoBox, {UploadableVideoBoxRef} from "./UploadableVideoBox";
import useDocBatchClient, {Sentence} from "../../../utils/docbatch_client";
import VideoTransResultBox from "./VideoTransResultBox";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";
import {ModelConfig} from "../../../utils/WebAppConfig";
import {withSwal} from "react-sweetalert2";




const VideoTransOnceApp = withSwal((props: any) => {
    const docBatchClient = useDocBatchClient()
    const [fileId, setFileId] = useState<string | null>(null)
    const [fileName, setFileName] = useState<string | null>(null)
    const [taskId, setTaskId] = useState<string | null>(null)
    const [taskStatus, setTaskStatus] = useState<string>("idle")
    const [progressText, setTaskProgressText] = useState<string>("")
    const [progressValue, setTaskProgressValue] = useState<number>(0)
    const [sentences, setSentences] = useState<Sentence[]>([])
    const [currentPlayingSentenceIndex, setCurrentPlayingSentenceIndex] = useState<number>(-1)
    const [fromLang, setFromLang] = useState<LangInfo>({value: "en", label: "英语"})
    const [toLang, setToLang] = useState<LangInfo>({value: "zh", label: "中文"})
    const [modelConfig, setModelConfig] = useState<ModelConfig>()
    const [isWorking, setIsWorking] = useState(false)
    const {swal} = props



    const handleOnTranslateClick = async () => {
        if(!modelConfig) {
            swal.fire("错误", "请先选择模型", "error")
            return
        }
        setIsWorking(true)
        const tid = await docBatchClient.addTaskOne({
            task_type: "video_trans",
            file_id: fileId!,
            base_model: modelConfig.modelName,
            file_name: fileName!,
            video_trans_option: {
                from_lang_code: fromLang.value,
                from_lang_name: fromLang.label,
                trans_engine: modelConfig.modelName == "nllb" ? "nllb" : "llm",
                to_lang_code: toLang.value,
                to_lang_name: toLang.label
            }
        })
        console.log("tid is " + tid)
        setTaskId(tid)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if(taskId) {
                auth_fetch_post_json(Conf.urlSecAiDocBatchTaskLookAt, {
                    'task_id': parseInt(taskId)
                })
                    .then((jo: any) => {
                        const data = jo['data']
                        setTaskStatus(data['status'])
                        setTaskProgressText(data['progress_text'])
                        setTaskProgressValue(data['progress_value'])
                        if(data['status'] === 'finished') {
                            clearInterval(timer)
                            const result_url = data['result_url']
                            console.log("result url is " + result_url)
                            fetch(result_url, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                }
                            }).then(response => {
                                console.log(response)
                                if(response.status === 200) {
                                    return response.json()
                                } else {
                                    console.error("error: " + response.status)
                                }
                            }).then((data) => {
                                setSentences(data)
                            }).finally(() => {
                                setIsWorking(false)
                            })

                        } else if(data['status'] === 'error') {
                            clearInterval(timer)
                            setIsWorking(false)
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    }).finally(() => {
                })
            }
        }, 1000)
        return () => {
            console.log("clean timer")
            clearInterval(timer)
        }
    }, [taskId]);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('视频翻译'))
    }, []);
    return (
        <>
            <Row>
                <Col md={12} xl={12}>
                    <div className="tool-bar">
                        <TransLangSelectGroup
                            defaultFromLang={{value: "en", label: "英语"}}
                            defaultToLang={{value: "zh", label: "中文"}}
                            defaultEngine={{value: "llm", label: "组合模型"}}
                            onFromLangChanged={(e)=>{setFromLang(e)}}
                            onToLangChanged={(e)=>{setToLang(e)}}
                            onEngineChanged={(e) => {()=>{}} }
                            onModelConfigChanged={setModelConfig}
                        />


                        <Button
                            onClick={handleOnTranslateClick}
                            disabled={isWorking}
                        >翻译
                        </Button>
                        <span>{
                            taskStatus === 'idle'? "正在排队" :
                                taskStatus === 'processing'? `正在处理` :
                                    taskStatus === 'finished'? "处理成功" :
                                        taskStatus === 'error'? "处理失败" : "未知状态"
                        }</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6} xl={6}>
                    <UploadableVideoBox
                        onVideoFileChanged={(fileId, fileName, fileUrl) => {
                            setFileId(fileId)
                            setFileName(fileName)
                        }}
                        onTimeUpdate={(currentTime) => {
                            /*
                            let i = 0
                            for(let sen of sentences) {
                                if (currentTime >= sen.start && currentTime < sen.stop) {
                                    // setCurrentSubTitle(sen['text'])
                                    setCurrentPlayingSentenceIndex(i)
                                    console.log("index is " + i)
                                    break
                                }
                                i++
                            }
                             */

                        }
                    } />

                </Col>
                <Col md={6} xl={6}  style={{height: "50vh"}}>
                    <VideoTransResultBox  sentences={sentences} highLightIndex={currentPlayingSentenceIndex}></VideoTransResultBox>
                </Col>
            </Row>
        </>
    );
});

export default VideoTransOnceApp;
