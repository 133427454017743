import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
export const ocrLangs = [
        {"value": "ch", "label": "中文"},
        {"value": "en", "label": "英文"},
        {"value": "fr", "label": "法文"},
        {"value": "german", "label": "德文"},
        {"value": "japan", "label": "日文"},
        {"value": "korean", "label": "韩文"},
        {"value": "chinese_cht", "label": "中文繁体"},
        {"value": "it", "label": "意大利文"},
        {"value": "es", "label": "西班牙文"},
        {"value": "pt", "label": "葡萄牙文"},
        {"value": "ru", "label": "俄罗斯文"},
        {"value": "ar", "label": "阿拉伯文"},
        {"value": "hi", "label": "印地文"},
        {"value": "ug", "label": "维吾尔"},
        {"value": "fa", "label": "波斯文"},
        {"value": "ur", "label": "乌尔都文"},
        {"value": "rs_latin", "label": "塞尔维亚文（latin）"},
        {"value": "oc", "label": "欧西坦文"},
        {"value": "mr", "label": "马拉地文"},
        {"value": "ne", "label": "尼泊尔文"},
        {"value": "rs_cyrillic", "label": "塞尔维亚文（cyrillic）"},
        {"value": "mi", "label": "毛利文"},
        {"value": "ms", "label": "马来文"},
        {"value": "mt", "label": "马耳他文"},
        {"value": "nl", "label": "荷兰文"},
        {"value": "no", "label": "挪威文"},
        {"value": "pl", "label": "波兰文"},
        {"value": "ro", "label": "罗马尼亚文"},
        {"value": "sk", "label": "斯洛伐克文"},
        {"value": "sl", "label": "斯洛文尼亚文"},
        {"value": "sq", "label": "阿尔巴尼亚文"},
        {"value": "sv", "label": "瑞典文"},
        {"value": "sw", "label": "西瓦希里文"},
        {"value": "tl", "label": "塔加洛文"},
        {"value": "tr", "label": "土耳其文"},
        {"value": "uz", "label": "乌兹别克文"},
        {"value": "vi", "label": "越南文"},
        {"value": "mn", "label": "蒙古文"},
        {"value": "bg", "label": "保加利亚文"},
        {"value": "uk", "label": "乌克兰文"},
        {"value": "be", "label": "白俄罗斯文"},
        {"value": "te", "label": "泰卢固文"},
        {"value": "abq", "label": "阿巴扎文"},
        {"value": "ta", "label": "泰米尔文"},
        {"value": "af", "label": "南非荷兰文"},
        {"value": "az", "label": "阿塞拜疆文"},
        {"value": "bs", "label": "波斯尼亚文"},
        {"value": "cs", "label": "捷克文"},
        {"value": "cy", "label": "威尔士文"},
        {"value": "da", "label": "丹麦文"},
        {"value": "et", "label": "爱沙尼亚文"},
        {"value": "ga", "label": "爱尔兰文"},
        {"value": "hr", "label": "克罗地亚文"},
        {"value": "hu", "label": "匈牙利文"},
        {"value": "id", "label": "印尼文"},
        {"value": "is", "label": "冰岛文"},
        {"value": "ku", "label": "库尔德文"},
        {"value": "lt", "label": "立陶宛文"},
        {"value": "lv", "label": "拉脱维亚文"},
        {"value": "dar", "label": "达尔瓦文"},
        {"value": "inh", "label": "因古什文"},
        {"value": "lbe", "label": "拉克文"},
        {"value": "lez", "label": "莱兹甘文"},
        {"value": "tab", "label": "塔巴萨兰文"},
        {"value": "bh", "label": "比尔哈文"},
        {"value": "mai", "label": "迈蒂利文"},
        {"value": "ang", "label": "昂加文"},
        {"value": "bho", "label": "孟加拉文"},
        {"value": "mah", "label": "摩揭陀文"},
        {"value": "sck", "label": "那格浦尔文"},
        {"value": "new", "label": "尼瓦尔文"},
        {"value": "sa", "label": "沙特阿拉伯文"},
        {"value": "ady", "label": "阿迪赫文"}

]

export const ocrNllbMap = {
    "ch": "zho_Hans",          // 中文
    "en": "eng_Latn",          // 英文
    "fr": "fra_Latn",          // 法文
    "german": "deu_Latn",      // 德文
    "japan": "jpn_Jpan",       // 日文
    "korean": "kor_Hang",      // 韩文
    "chinese_cht": "zho_Hant", // 中文繁体
    "it": "ita_Latn",          // 意大利文
    "es": "spa_Latn",          // 西班牙文
    "pt": "por_Latn",          // 葡萄牙文
    "ru": "rus_Cyrl",          // 俄罗斯文
    "ar": "arb_Arab",          // 阿拉伯文
    "hi": "hin_Deva",          // 印地文
    "ug": "uig_Arab",          // 维吾尔
    "fa": "pes_Arab",          // 波斯文
    "ur": "urd_Arab",          // 乌尔都文
    "rs_latin": "srp_Latn",    // 塞尔维亚文（latin）
    "oc": "oci_Latn",          // 欧西坦文
    "mr": "mar_Deva",          // 马拉地文
    "ne": "npi_Deva",          // 尼泊尔文
    "rs_cyrillic": "srp_Cyrl", // 塞尔维亚文（cyrillic）
    "mi": "mri_Latn",          // 毛利文
    "ms": "zsm_Latn",          // 马来文
    "mt": "mlt_Latn",          // 马耳他文
    "nl": "nld_Latn",          // 荷兰文
    "no": "nob_Latn",          // 挪威文
    "pl": "pol_Latn",          // 波兰文
    "ro": "ron_Latn",          // 罗马尼亚文
    "sk": "slk_Latn",          // 斯洛伐克文
    "sl": "slv_Latn",          // 斯洛文尼亚文
    "sq": "als_Latn",          // 阿尔巴尼亚文
    "sv": "swe_Latn",          // 瑞典文
    "sw": "swh_Latn",          // 西瓦希里文
    "tl": "tgl_Latn",          // 塔加洛文
    "tr": "tur_Latn",          // 土耳其文
    "uz": "uzn_Latn",          // 乌兹别克文
    "vi": "vie_Latn",          // 越南文
    "mn": "khk_Cyrl",          // 蒙古文
    "bg": "bul_Cyrl",          // 保加利亚文
    "uk": "ukr_Cyrl",          // 乌克兰文
    "be": "bel_Cyrl",          // 白俄罗斯文
    "te": "tel_Telu",          // 泰卢固文
    "ta": "tam_Taml",          // 泰米尔文
    "af": "afr_Latn",          // 南非荷兰文
    "az": "azj_Latn",          // 阿塞拜疆文
    "bs": "bos_Latn",          // 波斯尼亚文
    "cs": "ces_Latn",          // 捷克文
    "cy": "cym_Latn",          // 威尔士文
    "da": "dan_Latn",          // 丹麦文
    "et": "est_Latn",          // 爱沙尼亚文
    "hr": "hrv_Latn",          // 克罗地亚文
    "hu": "hun_Latn",          // 匈牙利文
    "id": "ind_Latn",          // 印尼文
    "is": "isl_Latn",          // 冰岛文
    "lt": "lit_Latn",          // 立陶宛文
    "lv": "lvs_Latn"           // 拉脱维亚文
}

export const ocrLlmMap = {
    "ch": "zh",           // 中文
    "en": "en",           // 英文
    "fr": "fr",           // 法文
    "german": "de",       // 德文
    "japan": "ja",        // 日文
    "korean": "ko",       // 韩文
    "chinese_cht": "cht", // 中文繁体
    "it": "it",           // 意大利文
    "es": "es",           // 西班牙文
    "pt": "pt",           // 葡萄牙文
    "ru": "ru",           // 俄罗斯文
    "ar": "ar",           // 阿拉伯文
    "tr": "tr",           // 土耳其文
    "vi": "vi",           // 越南文
    "nl": "nl",           // 荷兰文
    "pl": "pl",           // 波兰文
    "id": "id"            // 印尼文
  }

interface OcrResult {
    imageUrl?: string
    txtUrl?: string
    jsonUrl?: string
    txt?: string
    translatedText?: string
}
function useOcr() {
    const [ocrProgress, setOcrProgress] = useState(0);
    const [ocrResult, setOcrResult] = useState<OcrResult>({});
    const [isOcring, setIsOrcing] = useState(false);
    const [statusText, setStatusText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startOcr = useCallback((fileId: string, langCode: string, transEnabled: boolean, transFromLangName: string, transToLangName: string) => {
        if (socketRef.current) {
            socketRef.current.close();
        }
        // @ts-ignore
        const socket = new WebSocket(Conf.urlSecAiOcrFileConvert);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsOrcing(true)
            socket.send(JSON.stringify({
                action: "ocr",
                source: fileId,
                langCode: langCode,
                transEnabled: transEnabled,
                transFromLangName: transFromLangName,
                transToLangName: transToLangName
            }));
        }

        socket.onmessage = (event: any) => {
            console.log("onmessage " + event.data)
            const json = JSON.parse(event.data);
            if(json.action === "progress") {
                setOcrProgress(json.progress);
            } else if(json.action === "finished") {
                setOcrProgress(100);
                console.log("setOcrResult")
                console.log(json.result)
                setOcrResult(json.result)
            } else if(json.action === "error") {
                setErrorMessage(json['reason'])
            }
        }
        socket.onclose = () => {
            setIsOrcing(false)
        }
    }, []);

    const stopOcr = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);

    const findOcrLang = (langCode: string) => {
        const found = ocrLangs.find((lang) => lang.value === langCode)
        if(found) {
            return found['label']
        } else {
            return langCode
        }
    }

    const getOcrLangCodesListForNllb = () => {
        return ocrLangs.map((lang) => ocrNllbMap[lang.value])
    }

    const getOcrLangCodesListForLlm = () => {
        return ocrLangs.map((lang) => ocrLlmMap[lang.value])
    }   

    return {
        ocrProgress,
        startOcr,
        stopOcr,
        findOcrLang,
        ocrLangs,
        ocrResult,
        isOcring,
        statusText,
        errorMessage,
        getOcrLangCodesListForNllb,
        getOcrLangCodesListForLlm
    };
}

export default useOcr;
