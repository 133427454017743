import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";



// dummy data
import { users, ChatUser } from "./data";
import avatar_chathelper_b from "../../../assets/images/aichat/bots/chathelper_b.png";
import ChatArea from "../AiChat/ChatArea";
import KbDocBases, {KbDocBase} from "./KbDocBases";
import {KbChatConf} from "../AiChat/data";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";

import './index.css'

// AiChatApp
const KbChatApp = () => {
    const [selectedUser, setSelectedUser] = useState<ChatUser>({
            id: 2,
            robotId: "chatFile",
            name: "内部知识库",
            avatar: avatar_chathelper_b,
            lastMessage: "",
            totalUnread: 3,
            lastMessageOn: "4:30am",
            email: "support@coderthemes.com",
            phone: "+1 456 9595 9594",
            location: "California, USA",
            languages: "English, German, Spanish",
            groups: "Work, Favourties",
        }
    );
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('内部知识库2.0'))
    }, []);

    const [selectedChatDocBase, setSelectedChatDocBase] = useState<KbDocBase>();
    const [kbChatConf, setKbChatConf] = useState<KbChatConf>(
        {
            history_len: 1,
            temperature: 0.35,
            top_k: 3,
            score_threshold: 1.00
        }
    );



    const onDocBaseChanged = (docBase: KbDocBase) => {
        setSelectedChatDocBase(docBase);
    };

    const onKbChatConfChanged = (value: KbChatConf) => {
        setKbChatConf(value)
    }
    return (
        <div className={'kb-chat-root'} style={{display: 'flex', flexDirection: 'row'}}>
            <KbDocBases onDocBaseSelect={onDocBaseChanged} onKbChatConfChanged={onKbChatConfChanged} />
            <ChatArea isUsingInKb={true}  selectedUser={selectedUser} chatDocBaseUuid={selectedChatDocBase?.uuid} kbChatConf={kbChatConf}  />
        </div>
    );
};

export default KbChatApp;
