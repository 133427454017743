// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-widget-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}
.avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.avatar-image {
    width: 64px;
    height: 64px;
}

.title-desc-container {
    flex: 1 1;
    flex-direction: column;
    gap: 10px;

}`, "",{"version":3,"sources":["webpack://./src/pages/apps/AiTool/widgets/BaseWidget.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,yCAAyC;IACzC,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,SAAO;IACP,sBAAsB;IACtB,SAAS;;AAEb","sourcesContent":[".base-widget-container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 16px;\r\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\r\n    margin-bottom: 20px;\r\n    padding: 20px;\r\n}\r\n.avatar-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.avatar-image {\r\n    width: 64px;\r\n    height: 64px;\r\n}\r\n\r\n.title-desc-container {\r\n    flex: 1;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
