import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import { appMenuReducer } from "./menu/reducer";

export const allReducer = combineReducers({
  Auth,
  Layout,
  appmenu: appMenuReducer
});


export type AllState = ReturnType<typeof allReducer>;