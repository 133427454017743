import { useState, useCallback, useRef, useEffect } from 'react';
import Conf from "../pages/apps/Utils/Conf";
export const asrLangs = [
    {"value": "zh", "label": "中文"},
    {"value": "en", "label": "英语"},
    {"value": "de", "label": "德语"},
    {"value": "es", "label": "西班牙语"},
    {"value": "ru", "label": "俄语"},
    {"value": "ko", "label": "韩语"},
    {"value": "fr", "label": "法语"},
    {"value": "ja", "label": "日语"},
    {"value": "pt", "label": "葡萄牙语"},
    {"value": "tr", "label": "土耳其语"},
    {"value": "pl", "label": "波兰语"},
    {"value": "ca", "label": "加泰罗尼亚语"},
    {"value": "nl", "label": "荷兰语"},
    {"value": "ar", "label": "阿拉伯语"},
    {"value": "sv", "label": "瑞典语"},
    {"value": "it", "label": "意大利语"},
    {"value": "id", "label": "印尼语"},
    {"value": "hi", "label": "印地语"},
    {"value": "fi", "label": "芬兰语"},
    {"value": "vi", "label": "越南语"},
    {"value": "he", "label": "希伯来语"},
    {"value": "uk", "label": "乌克兰语"},
    {"value": "el", "label": "希腊语"},
    {"value": "ms", "label": "马来语"},
    {"value": "cs", "label": "捷克语"},
    {"value": "ro", "label": "罗马尼亚语"},
    {"value": "da", "label": "丹麦语"},
    {"value": "hu", "label": "匈牙利语"},
    {"value": "ta", "label": "泰米尔语"},
    {"value": "no", "label": "挪威语"},
    {"value": "th", "label": "泰语"},
    {"value": "ur", "label": "乌尔都语"},
    {"value": "hr", "label": "克罗地亚语"},
    {"value": "bg", "label": "保加利亚语"},
    {"value": "lt", "label": "立陶宛语"},
    {"value": "la", "label": "拉丁语"},
    {"value": "mi", "label": "毛利语"},
    {"value": "ml", "label": "马拉雅拉姆语"},
    {"value": "cy", "label": "威尔士语"},
    {"value": "sk", "label": "斯洛伐克语"},
    {"value": "te", "label": "泰卢固语"},
    {"value": "fa", "label": "波斯语"},
    {"value": "lv", "label": "拉脱维亚语"},
    {"value": "bn", "label": "孟加拉语"},
    {"value": "sr", "label": "塞尔维亚语"},
    {"value": "az", "label": "阿塞拜疆语"},
    {"value": "sl", "label": "斯洛文尼亚语"},
    {"value": "kn", "label": "卡纳达语"},
    {"value": "et", "label": "爱沙尼亚语"},
    {"value": "mk", "label": "马其顿语"},
    {"value": "br", "label": "布列塔尼语"},
    {"value": "eu", "label": "巴斯克语"},
    {"value": "is", "label": "冰岛语"},
    {"value": "hy", "label": "亚美尼亚语"},
    {"value": "ne", "label": "尼泊尔语"},
    {"value": "mn", "label": "蒙古语"},
    {"value": "bs", "label": "波斯尼亚语"},
    {"value": "kk", "label": "哈萨克语"},
    {"value": "sq", "label": "阿尔巴尼亚语"},
    {"value": "sw", "label": "斯瓦希里语"},
    {"value": "gl", "label": "加利西亚语"},
    {"value": "mr", "label": "马拉提语"},
    {"value": "pa", "label": "旁遮普语"},
    {"value": "si", "label": "僧伽罗语"},
    {"value": "km", "label": "高棉语"},
    {"value": "sn", "label": "修纳语"},
    {"value": "yo", "label": "约鲁巴语"},
    {"value": "so", "label": "索马里语"},
    {"value": "af", "label": "阿非利卡语"},
    {"value": "oc", "label": "奥克西唐语"},
    {"value": "ka", "label": "格鲁吉亚语"},
    {"value": "be", "label": "白俄罗斯语"},
    {"value": "tg", "label": "塔吉克语"},
    {"value": "sd", "label": "信德语"},
    {"value": "gu", "label": "古吉拉特语"},
    {"value": "am", "label": "阿姆哈拉语"},
    {"value": "yi", "label": "依地语"},
    {"value": "lo", "label": "老挝语"},
    {"value": "uz", "label": "乌兹别克语"},
    {"value": "fo", "label": "法罗语"},
    {"value": "ht", "label": "海地克里奥尔语"},
    {"value": "ps", "label": "普什图语"},
    {"value": "tk", "label": "土库曼语"},
    {"value": "nn", "label": "新挪威语"},
    {"value": "mt", "label": "马耳他语"},
    {"value": "sa", "label": "梵语"},
    {"value": "lb", "label": "卢森堡语"},
    {"value": "my", "label": "缅甸语"},
    {"value": "bo", "label": "藏语"},
    {"value": "tl", "label": "塔加路语"},
    {"value": "mg", "label": "马尔加什语"},
    {"value": "as", "label": "阿萨姆语"},
    {"value": "tt", "label": "鞑靼语"},
    {"value": "haw", "label": "夏威夷语"},
    {"value": "ln", "label": "林加拉语"},
    {"value": "ha", "label": "豪萨语"},
    {"value": "ba", "label": "巴什基尔语"},
    {"value": "jw", "label": "爪哇语"},
    {"value": "su", "label": "巽他语"}
]
export const asrNllbMap = {
    "zh": "zho_Hans",  // 中文(简体)
    "en": "eng_Latn",  // 英语
    "de": "deu_Latn",  // 德语
    "es": "spa_Latn",  // 西班牙语
    "ru": "rus_Cyrl",  // 俄语
    "ko": "kor_Hang",  // 韩语
    "fr": "fra_Latn",  // 法语
    "ja": "jpn_Jpan",  // 日语
    "pt": "por_Latn",  // 葡萄牙语
    "tr": "tur_Latn",  // 土耳其语
    "pl": "pol_Latn",  // 波兰语
    "ca": "cat_Latn",  // 加泰罗尼亚语
    "nl": "nld_Latn",  // 荷兰语
    "ar": "arb_Arab",  // 阿拉伯语
    "sv": "swe_Latn",  // 瑞典语
    "it": "ita_Latn",  // 意大利语
    "id": "ind_Latn",  // 印尼语
    "hi": "hin_Deva",  // 印地语
    "fi": "fin_Latn",  // 芬兰语
    "vi": "vie_Latn",  // 越南语
    "he": "heb_Hebr",  // 希伯来语
    "uk": "ukr_Cyrl",  // 乌克兰语
    "el": "ell_Grek",  // 希腊语
    "ms": "zsm_Latn",  // 马来语
    "cs": "ces_Latn",  // 捷克语
    "ro": "ron_Latn",  // 罗马尼亚语
    "da": "dan_Latn",  // 丹麦语
    "hu": "hun_Latn",  // 匈牙利语
    "ta": "tam_Taml",  // 泰米尔语
    "no": "nob_Latn",  // 挪威语
    "th": "tha_Thai",  // 泰语
    "ur": "urd_Arab",  // 乌尔都语
    "hr": "hrv_Latn",  // 克罗地亚语
    "bg": "bul_Cyrl",  // 保加利亚语
    "lt": "lit_Latn",  // 立陶宛语
    "la": "lat_Latn",  // 拉丁语(无对应，备用)
    "mi": "mri_Latn",  // 毛利语
    "ml": "mal_Mlym",  // 马拉雅拉姆语
    "cy": "cym_Latn",  // 威尔士语
    "sk": "slk_Latn",  // 斯洛伐克语
    "te": "tel_Telu",  // 泰卢固语
    "fa": "pes_Arab",  // 波斯语
    "lv": "lvs_Latn",  // 拉脱维亚语
    "bn": "ben_Beng",  // 孟加拉语
    "sr": "srp_Cyrl",  // 塞尔维亚语
    "az": "azj_Latn",  // 阿塞拜疆语
    "sl": "slv_Latn",  // 斯洛文尼亚语
    "kn": "kan_Knda",  // 卡纳达语
    "et": "est_Latn",  // 爱沙尼亚语
    "mk": "mkd_Cyrl",  // 马其顿语
    "br": "bre_Latn",  // 布列塔尼语(无对应，备用)
    "eu": "eus_Latn",  // 巴斯克语
    "is": "isl_Latn",  // 冰岛语
    "hy": "hye_Armn",  // 亚美尼亚语
    "ne": "npi_Deva",  // 尼泊尔语
    "mn": "khk_Cyrl",  // 蒙古语
    "bs": "bos_Latn",  // 波斯尼亚语
    "kk": "kaz_Cyrl",  // 哈萨克语
    "sq": "als_Latn",  // 阿尔巴尼亚语
    "sw": "swh_Latn",  // 斯瓦希里语
    "gl": "glg_Latn",  // 加利西亚语
    "mr": "mar_Deva",  // 马拉地语
    "pa": "pan_Guru",  // 旁遮普语
    "si": "sin_Sinh",  // 僧伽罗语
    "km": "khm_Khmr",  // 高棉语
    "sn": "sna_Latn",  // 修纳语
    "yo": "yor_Latn",  // 约鲁巴语
    "so": "som_Latn",  // 索马里语
    "af": "afr_Latn",  // 南非语
    "oc": "oci_Latn",  // 奥克语
    "ka": "kat_Geor",  // 格鲁吉亚语
    "be": "bel_Cyrl",  // 白俄罗斯语
    "tg": "tgk_Cyrl",  // 塔吉克语
    "sd": "snd_Arab",  // 信德语
    "gu": "guj_Gujr",  // 古吉拉特语
    "am": "amh_Ethi",  // 阿姆哈拉语
    "yi": "ydd_Hebr",  // 依地语
    "lo": "lao_Laoo",  // 老挝语
    "uz": "uzn_Latn",  // 乌兹别克语
    "fo": "fao_Latn",  // 法罗语
    "ht": "hat_Latn",  // 海地语
    "ps": "pbt_Arab",  // 普什图语
    "tk": "tuk_Latn",  // 土库曼语
    "nn": "nno_Latn",  // 新挪威语
    "mt": "mlt_Latn",  // 马耳他语
    "sa": "san_Deva",  // 梵语
    "lb": "ltz_Latn",  // 卢森堡语
    "my": "mya_Mymr",  // 缅甸语
    "bo": "bod_Tibt",  // 藏语
    "tl": "tgl_Latn",  // 他加禄语
    "mg": "mlg_Latn",  // 马尔加什语(无对应，备用)
    "as": "asm_Beng",  // 阿萨姆语
    "tt": "tat_Cyrl",  // 鞑靼语
    "haw": "haw_Latn", // 夏威夷语(无对应，备用)
    "ln": "lin_Latn",  // 林加拉语
    "ha": "hau_Latn",  // 豪萨语
    "ba": "bak_Cyrl",  // 巴什基尔语
    "jw": "jav_Latn",  // 爪哇语
    "su": "sun_Latn"   // 巽他语
  }

export const asrLlmMap = {
"zh": "zh",     // 中文
"en": "en",     // 英语
"de": "de",     // 德语
"es": "es",     // 西班牙语
"ru": "ru",     // 俄语
"ko": "ko",     // 韩语
"fr": "fr",     // 法语
"ja": "ja",     // 日语
"pt": "pt",     // 葡萄牙语
"tr": "tr",     // 土耳其语
"pl": "pl",     // 波兰语
"nl": "nl",     // 荷兰语
"ar": "ar",     // 阿拉伯语
"it": "it",     // 意大利语
"id": "id",     // 印尼语
"vi": "vi",     // 越南语
"th": "th"      // 泰语
  }
function useAsr() {
    const [asrProgress, setAsrProgress] = useState(0);
    const [asrStatus, setAsrStatus] = useState("");
    const [asrSentences, setAsrSentences] = useState([]);
    const [asrResult, setAsrResult] = useState("");
    const [isAsring, setIsAsring] = useState(false)
    // @ts-ignore
    const socketRef = useRef<WebSocket | null>(null);  // Explicitly type the ref here

    const startAsrByFile = useCallback((fileId: string, langCode: string) => {
        setAsrStatus("准备转写")
        setIsAsring(true)
        try {
            if (socketRef.current) {
                socketRef.current.close();
            }
        } catch (e) {
            console.log(e)
        }
        try {
            setAsrStatus("正在连接")
            const socket = new WebSocket(Conf.urlSecaiAsrConvert);
            socketRef.current = socket;
            let timer_ping: NodeJS.Timeout
            socket.onopen = () => {
                timer_ping = setInterval(() => {
                    try {
                        socket.send(JSON.stringify({
                            action: "ping"
                        }))
                    } catch(e) {
                        console.log(e)
                    }
                }, 5000)
                setAsrStatus("发送转写请求")
                socket.send(JSON.stringify({
                    action: "convertFile",
                    source: fileId,
                    langCode: langCode
                }));
            }


            socket.onmessage = (event: any) => {
                console.log(event.data)
                const json = JSON.parse(event.data);
                if(json.action === "progress") {
                    setAsrProgress(json.progress);
                } else if(json.action === "finished") {
                    setAsrProgress(100);
                    setAsrResult(json.result)
                    setAsrSentences(json.sentences)
                    setAsrStatus("转写成功")
                } else if(json.action === "pong") {
                    console.log("get pong")
                } else {
                    console.log("unknown action")
                }
            }

            socket.onerror = (err: any) => {
                setAsrStatus("转写失败 " + err)
                setIsAsring(false)
            }

            socket.onclose = () => {
                console.log("closed..")
                if(timer_ping) {
                    clearInterval(timer_ping)
                }
                setIsAsring(false)
            }
        } catch(err) {
            console.log(err)
            setIsAsring(false)
            setAsrStatus("转写失败 " + err)
        }


    }, []);

    const stopAsr = useCallback(() => {
        if (socketRef.current) {
            socketRef.current.close();
        }
    }, []);

    const getAsrLangCodesListForNllb = () => {
        return asrLangs.map((lang) => asrNllbMap[lang.value])
    }

    const getAsrLangCodesListForLlm = () => {
        return asrLangs.map((lang) => asrLlmMap[lang.value])
    }   

    return {
        asrProgress,
        startAsrByFile,
        stopAsr, asrLangs,
        asrResult,
        asrSentences,
        isAsring,
        getAsrLangCodesListForNllb,
        getAsrLangCodesListForLlm
    };
}

export default useAsr;
