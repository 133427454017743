import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";

// components
import PageTitle from "../../../components/PageTitle";

import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import { appTitleSet } from "../../../redux/actions";
import Conf from "../Utils/Conf";
import useWebAppConfig from "../../../utils/WebAppConfig";



const AiReaderApp = () => {
    const dispatch = useDispatch<AppDispatch>();
    const webAppConfig = useWebAppConfig()

    useEffect(() => {
        dispatch(appTitleSet('智慧阅读'))
        webAppConfig.loadWebAppConfig()

    }, []);
    
    return (
        <iframe
            src={webAppConfig.urlAiReader}
            width="100%"
            height="100%"
            >

        </iframe>
    );
};

export default AiReaderApp;