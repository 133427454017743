import Conf from "../pages/apps/Utils/Conf";
import {APICore} from "../helpers/api/apiCore";
import {useState} from "react";

export async function auth_fetch_post_json(url: string, body: any) {
    const api =  new APICore()
    return new Promise((resolve, reject) => {
        if(!api.isUserAuthenticated()) {
            reject("用户未登录")
        }
        if(!api.getLoggedInUser()) {
            reject("用户未登录")
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + api.getLoggedInUser().token,
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                if(response.status == 401) {
                    reject("未授权的用户401")
                } else if(response.status == 500) {
                    reject("服务器内部错误500")
                } else if(response.status == 200) {
                    resolve(response.json())
                } else {
                    reject("未知网络状态" + response.status)
                }
            })
            .catch((error) => {
                reject(error)
            });
    })

}



export async function auth_fetch_post(url: string, body: any): Promise<Response> {
    const api =  new APICore()


    return new Promise((resolve, reject) => {
        if(!api.isUserAuthenticated()) {
            reject("用户未登录")
            return;
        }
        if(!api.getLoggedInUser()) {
            reject("用户未登录")
            return;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + api.getLoggedInUser().token,
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                if(response.status == 401) {
                    reject("未授权的用户401")
                } else if(response.status == 500) {
                    reject("服务器内部错误500")
                } else if(response.status == 200) {
                    resolve(response)
                } else {
                    reject("未知网络状态" + response.status)
                }
            })
            .catch((error) => {
                reject(error)
            });
    })

}

export function useAuthFetchPost() {
    const [isPosting, setIsPosting] = useState(false)
    const doPost = (url: string, body: any): Promise<any> => {
        setIsPosting(true)
        return new Promise<void>((resolve, reject) => {
            auth_fetch_post(url, body).then((response) => {
                setIsPosting(false)
                resolve(response.json())
            }).catch((error) => {
                setIsPosting(false)
                reject(error)
            })
        })

    }
    return {
        isPosting,
        doPost,
    }
}

