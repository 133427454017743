import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea } from "../WidgetAppParam";

const PsychologyWikiApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextArea("question", "心理知识科普", "请输入要提问的心理知识,比如:什么是厌学情绪", "心理知识问题"),
    ];

    const template = "PsychologyWiki";
    const title = "心理知识科普";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default PsychologyWikiApp;