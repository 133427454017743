import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const CreateHelperApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "创作主题", "请输入创作的主题", "文章主题"),
        new WidgetAppParamTextArea("content", "创作内容", "请输入创作的内容", "创作内容"),
    ];

    const template = "CreateHelper";
    const title = "创作助手";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default CreateHelperApp;