import avatar1 from "../../../assets/images/users/user-1.jpg";
import avatar2 from "../../../assets/images/users/user-2.jpg";
import avatar3 from "../../../assets/images/users/user-3.jpg";
import avatar4 from "../../../assets/images/users/user-4.jpg";
import avatar5 from "../../../assets/images/users/user-5.jpg";
import avatar6 from "../../../assets/images/users/user-6.jpg";
import avatar7 from "../../../assets/images/users/user-7.jpg";
import avatar8 from "../../../assets/images/users/user-8.jpg";
import avatar9 from "../../../assets/images/users/user-9.jpg";

import avatar_chathelper from "../../../assets/images/aichat/bots/chathelper.png";
import avatar_chathelper_b from "../../../assets/images/aichat/bots/chathelper_b.png";
import avatar_vnchat from "../../../assets/images/aichat/bots/vnchat.png";
import avatar_lawchat from "../../../assets/images/aichat/bots/lawchat.png";
import avatar_wpschat from "../../../assets/images/aichat/bots/wpschat.png";
import avatar_regexchat from "../../../assets/images/aichat/bots/regexchat.png";
import avatar_coldchat from "../../../assets/images/aichat/bots/coldchat.png";
import avatar_cnenchat from "../../../assets/images/aichat/bots/cnenchat.png";
import avatar_baikeask from "../../../assets/images/aichat/bots/baikeask.png";
import avatar_psychologydoctor from "../../../assets/images/aichat/bots/psychologydoctor.png";
import {useState} from "react";

export interface KbChatConf {
  history_len: number;
  temperature: number;
  top_k: number;
  score_threshold: number;
}
export interface ChatUser {
  id?: number;
  robotId?: string;
  name?: string;
  avatar?: string;
  lastMessage?: string;
  totalUnread?: number;
  lastMessageOn?: string;
  email?: string;
  phone?: string;
  location?: string;
  languages?: string;
  groups: string;
}

export interface ChatMessage {
  id: number;
  from: ChatUser;
  to: ChatUser;
  message: {
    type: string;
    value: any;
  };
  from_uuid?: string;
  files?: UserInputFile[];
  sendOn?: string;
}

export interface UserInputFile {
  fileName: string,
  fileSize: number,
  fileId: string,
}

const users: ChatUser[] = [
  {
    id: 1,
    robotId: "genericHelperA",
    name: "全能助手",
    avatar: avatar_chathelper,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 2,
    robotId: "vnchat",
    name: "越南百科助手",
    avatar: avatar_vnchat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 3,
    robotId: "lawchat",
    name: "法律助手",
    avatar: avatar_lawchat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 4,
    robotId: "baikeask",
    name: "百科问答",
    avatar: avatar_baikeask,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 5,
    robotId: "englishCorrector",
    name: "英语纠错",
    avatar: avatar_chathelper_b,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 6,
    robotId: "wpschat",
    name: "WPS教程助手",
    avatar: avatar_wpschat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 7,
    robotId: "regexchat",
    name: "正则表达式",
    avatar: avatar_regexchat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 8,
    robotId: "coldchat",
    name: "冷知识助手",
    avatar: avatar_coldchat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 9,
    robotId: "cnenchat",
    name: "中英互译",
    avatar: avatar_cnenchat,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
  {
    id: 10,
    robotId: "psychologydoctor",
    name: "心理疏导",
    avatar: avatar_psychologydoctor,
    lastMessage: "",
    totalUnread: 3,
    lastMessageOn: "4:30am",
    email: "support@coderthemes.com",
    phone: "+1 456 9595 9594",
    location: "California, USA",
    languages: "English, German, Spanish",
    groups: "Work, Favourties",
  },
];


const messages: ChatMessage[] = [];

const defaultTo = {
  id: 9,
  name: "Geneva M",
  avatar: avatar1,
  email: "support@coderthemes.com",
  phone: "+1 456 9595 9594",
  location: "California, USA",
  languages: "English, German, Spanish",
  groups: "Work, Friends",
};

for (const user of users) {
  messages.push(
    {
      id: 1,
      message: {
        type: "text",
        value: "Hello!",
      },
      to: defaultTo,
      from: user,
      sendOn: "10:00",
    },
    {
      id: 2,
      message: {
        type: "text",
        value: "Hi, How are you? What about our next meeting?",
      },
      to: user,
      from: defaultTo,
      sendOn: "10:01",
    },
    {
      id: 3,
      message: {
        type: "text",
        value: "Yeah everything is fine",
      },
      to: defaultTo,
      from: user,
      sendOn: "10:01",
    },
    {
      id: 4,
      message: {
        type: "text",
        value: "Awesome!",
      },
      to: user,
      from: defaultTo,
      sendOn: "10:02",
    },
    {
      id: 5,
      message: {
        type: "text",
        value: "Let's have it today if you are free",
      },
      to: defaultTo,
      from: user,
      sendOn: "10:03",
    },
    {
      id: 6,
      message: {
        type: "text",
        value: "Sure thing! let me know if 2pm works for you",
      },
      to: user,
      from: defaultTo,
      sendOn: "10:03",
    },
    {
      id: 7,
      message: {
        type: "text",
        value:
          "Sorry, I have another meeting scheduled at 2pm. Can we have it at 3pm instead?",
      },
      to: defaultTo,
      from: user,
      sendOn: "10:04",
    },
    {
      id: 8,
      message: {
        type: "text",
        value:
          "We can also discuss about the presentation talk format if you have some extra mins",
      },
      to: defaultTo,
      from: user,
      sendOn: "10:04",
    },
    {
      id: 9,
      message: {
        type: "text",
        value:
          "3pm it is. Sure, let's discuss about presentation format, it would be great to finalize today. I am attaching the last year format and assets here..",
      },
      to: user,
      from: defaultTo,
      sendOn: "10:05",
    },
    {
      id: 10,
      message: {
        type: "file",
        value: {
          file: "UBold-sketch.zip",
          size: "2.3MB",
        },
      },
      to: user,
      from: defaultTo,
      sendOn: "10:05",
    }
  );
}

const chatFileUser = {
      id: 2,
      robotId: "chatFile",
      name: "内部知识库",
      avatar: avatar_chathelper_b,
      lastMessage: "",
      totalUnread: 3,
      lastMessageOn: "4:30am",
      email: "support@coderthemes.com",
      phone: "+1 456 9595 9594",
      location: "California, USA",
      languages: "English, German, Spanish",
      groups: "Work, Favourties",
}


export { users, messages, chatFileUser };
