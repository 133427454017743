import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea } from "../WidgetAppParam";

const MindMapApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextArea("subject", "思维导图主题", "请输入思维导图的主题", "思维导图主题")
    ];

    const template = "MindMap";
    const title = "思维导图";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default MindMapApp;