import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const ActivityPlanApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "活动主题", "请输入活动主题", "活动主题"),
        new WidgetAppParamTextLine("date", "活动日期", "请输入活动日期", "活动日期"),
        new WidgetAppParamTextArea("content", "活动内容", "请输入活动内容", "活动内容"),
    ];

    const template = "ActivityPlan";
    const title = "活动方案";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default ActivityPlanApp;