import React, { useEffect, useState } from 'react';
import { auth_fetch_post_json } from './auth_fetch';
import Conf from '../pages/apps/Utils/Conf';
import Select, { StylesConfig } from 'react-select';

interface HotLib {
    file_name: string;
    hot_id: number;
}

interface SelectOption {
    label: string;
    value: number;
}

interface HotLibSelectProps {
    onHotLibSelected: (hotId: number) => void;
    isDisabled?: boolean
}

export const HotLibSelect: React.FC<HotLibSelectProps> = (props: HotLibSelectProps) => {
    const [selectedHotLib, setSelectedHotLib] = useState<SelectOption | null>(null);
    const [hotLibs, setHotLibs] = useState<HotLib[]>([]);

    const DEFAULT_LIB: HotLib = {
        file_name: '不使用热词库',
        hot_id: -1
    };

    const selectStyles: StylesConfig<SelectOption, false> = {
        control: (base) => ({
            ...base,
            width: '200px',
            borderRadius: '6px',
            borderColor: '#e2e8f0',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#3b82f6' : state.isFocused ? '#e2e8f0' : 'white',
            '&:active': {
                backgroundColor: '#60a5fa'
            }
        }),
        menu: (base) => ({
            ...base,
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
        })
    };

    useEffect(() => {
        props.onHotLibSelected(selectedHotLib?.value || DEFAULT_LIB.hot_id);
    }, [selectedHotLib]);

    const loadHotList = async () => {
        try {
            const response = await auth_fetch_post_json(Conf.urlHotList, {}) as {
                status: boolean;
                hot_list: HotLib[];
            };

            console.log('Hot list response:', JSON.stringify(response));

            if (response.status) {
                setHotLibs([DEFAULT_LIB, ...response.hot_list]);
            } else {
                setHotLibs([DEFAULT_LIB]);
            }

            setSelectedHotLib({
                label: DEFAULT_LIB.file_name,
                value: DEFAULT_LIB.hot_id
            });
        } catch (error) {
            console.error('Failed to load hot list:', error);
            setHotLibs([DEFAULT_LIB]);
            setSelectedHotLib({
                label: DEFAULT_LIB.file_name,
                value: DEFAULT_LIB.hot_id
            });
        }
    };

    useEffect(() => {
        loadHotList();
    }, []);

    const handleChange = (selectedOption: SelectOption | null) => {
        if (selectedOption) {
            setSelectedHotLib(selectedOption);
        }
    };

    return (
        <Select
            className="react-select react-select-container"
            classNamePrefix="react-select"
            isClearable={false}
            isSearchable={true}
            value={selectedHotLib}
            styles={selectStyles}
            isDisabled={props.isDisabled}
            options={hotLibs.map((item: HotLib) => ({
                value: item.hot_id,
                label: item.file_name
            }))}
            onChange={handleChange}
        />
    );
};