// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-trans-result-box-container {
    background-color: #dddddd;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.working-status-pane {
    text-align: center;
}
.sentences-pane {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
}

.sentence-item {
    margin-bottom: 10px;
}
.sentence-item-active {
    margin-bottom: 10px;
    background-color: #f1f1f1;

}
.start-time {
    margin-right: 8px;
    color: #303841;
}

.source-text {
    margin-right: 8px;
    color: #000000;
}

.target-text {
    color: #546ee5;
    font: bold 16px/24px 'Roboto', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/apps/AudioTransOnce/AudioTransResultBox.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,yBAAyB;;AAE7B;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,yCAAyC;AAC7C","sourcesContent":[".audio-trans-result-box-container {\r\n    background-color: #dddddd;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.working-status-pane {\r\n    text-align: center;\r\n}\r\n.sentences-pane {\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: auto;\r\n    padding: 10px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.sentence-item {\r\n    margin-bottom: 10px;\r\n}\r\n.sentence-item-active {\r\n    margin-bottom: 10px;\r\n    background-color: #f1f1f1;\r\n\r\n}\r\n.start-time {\r\n    margin-right: 8px;\r\n    color: #303841;\r\n}\r\n\r\n.source-text {\r\n    margin-right: 8px;\r\n    color: #000000;\r\n}\r\n\r\n.target-text {\r\n    color: #546ee5;\r\n    font: bold 16px/24px 'Roboto', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
