import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const ResearchReportApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "调研主题", "请输入调研的主题", "调研主题"),
        new WidgetAppParamTextArea("content", "调研内容", "请输入调研的大致内容", "调研内容"),
    ];

    const template = "ResearchReport";
    const title = "调研报告";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default ResearchReportApp;