import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";

import TextToImage from "./TextToImage";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Conf from "../Utils/Conf";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";




// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";

const TextToImageApp = () => {
    /*
     * modal handeling
     */
    const [show, setShow] = useState<boolean>(false);

    const onCloseModal = () => {
        setShow(false);
        setEventData({});
        setDateInfo({});
    };
    const onOpenModal = () => setShow(true);
    const [isEditable, setIsEditable] = useState<boolean>(false);

    /*
     * event data
     */
    // const [events, setEvents] = useState<EventInput[]>([...defaultEvents]);
    const [eventData, setEventData] = useState<EventInput>({});
    const [dateInfo, setDateInfo] = useState<any>({});


    useEffect(() => {
        // create dragable events
    }, []);

    /*
      calendar events
      */
    // on date click
    const onDateClick = (arg: DateClickArg) => {
        setDateInfo(arg);
        onOpenModal();
        setIsEditable(false);
    };

    // on event click
    const onEventClick = (arg: EventClickArg) => {
        const event = {
            id: String(arg.event.id),
            title: arg.event.title,
            className: arg.event.classNames[0],
        };
        setEventData(event);
        setIsEditable(true);
        onOpenModal();
    };




    // create new event
    const createNewEvent = () => {
        setIsEditable(false);
        onOpenModal();
    };

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('AI绘图'))
    }, []);

    return (
        <>

            <iframe src={Conf.urlTextToImage} style={{ width: '100%', height: '100vh' }} ></iframe>


        </>
    );
};

export default TextToImageApp;
