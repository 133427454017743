import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Button, Modal, Form} from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg, Draggable } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import classNames from "classnames";

import "react-nestable/dist/styles/index.css";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

import Users from "./Users";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";
import Select from "react-select";
import {FormInput} from "../../../components";
import Nestable from "react-nestable";
import {Link} from "react-router-dom";
import Conf from "../Utils/Conf";
import {users} from "../AiChat/data";
import {FormProvider, useForm} from "react-hook-form";
import { withSwal } from "react-sweetalert2";
import {APICore} from "../../../helpers/api/apiCore";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import {appTitleSet} from "../../../redux/layout/actions";




// import AddEditEvent from "./AddEditEvent";

// dummy data
// import { defaultEvents } from "./data";



const sizePerPageList = [
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "50",
        value: 50,
    },
    {
        text: "100",
        value: 100,
    },
    {
        text: "200",
        value: 200,
    },
    {
        text: "All",
        value: users.length,
    },
];

interface RenderItemProps {
    item: Record<string, any>;
    collapseIcon: React.ReactNode;
    handler: React.ReactNode;
    onEdit: (item: Record<string, any>, newName: string) => void;
    onRemove: (item: Record<string, any>) => void;
    onNewClick: (item: Record<string, any>) => void;
    onClick?: (item: Record<string, any>) => void;
}




const RenderItem = ({ item, collapseIcon, handler, onEdit, onRemove,onNewClick, onClick }: RenderItemProps) => {
    return (
        <div className="dd-list">
            <div className="dd-item">
                <div className="dd-handle">
                    {handler}
                    {collapseIcon}
                    <a href={'#'} onClick={()=> onClick ? onClick(item) : console.log("onClick null")}>{item.text}</a>
                    <a href={'#'} onClick={
                        ()=> {
                            const newName = prompt("修改部门名称", item.text);
                            if(!newName) {

                            } else {
                                onEdit(item, newName)
                            }


                        }
                    }>
                        <i className="mdi mdi-pencil"></i>
                    </a>


                    <a href={'#'}>
                        <i className="mdi mdi-plus" onClick={
                            ()=> {
                                onNewClick(item)
                            }
                        }></i>
                    </a>


                    {
                        (item.id != 0) && (<a href={'#'}><i className="mdi mdi-close" onClick={()=>onRemove(item)}></i></a>)
                    }

                </div>
                <div>

                </div>

            </div>

        </div>
    );
};

const UsersApp = withSwal((props: any) => {
    const { swal } = props;

    /*
     * modal handeling
     */
    const [show, setShow] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [groups, setGroups] = useState<any>([{ id: 0, text: "我的组织" }]);
    const [allGroups, setAllGroups] = useState<any>([{ id: 0, text: "我的组织" }]);
    const [users, setUsers] = useState<any>([]);
    const [newUserVisible, setNewUserVisible] = useState<boolean>(false);
    const [isUserModalEdit, seIsUserModalEdit] = useState<boolean>(false);

    const [currentGroup, setCurrentGroup] = useState<number>(0);
    const [currentGroupName, setCurrentGroupName] = useState<string>('');
    const [currentUser, setCurrentUser] = useState<number>(0);

    const userTable = useRef<any>(null);

    const api = new APICore()

    const columns = React.useMemo(
        () => [

            {
                Header: "ID",
                accessor: "id",
                sort: false,
            },
            {
                Header: "基本信息",
                accessor: 'basisInfo',
                Cell: (data: any) => (
                    <div>
                        <div><i className={"fas fa-user-alt me-2"}></i><strong>{data.row.original.name}</strong></div>
                        <div><i className={"fas fa-mail-bulk me-2"}></i>{data.row.original.email}</div>
                        <div><i className={"fas fa-phone me-2"}></i>{data.row.original.phone}</div>
                    </div>
                )
            },


            {
                Header: "描述",
                accessor: "desc",
                sort: false,
            },
            {
                Header: "状态",
                accessor: 'userStatus',
                Cell: (data: any) => (
                    <div>
                        {data.row.original.op_locked && (<div><i className={"fas fa-lock me-2"}></i><strong>锁定</strong></div>)}
                        {(data.row.original.op_status!="" && data.row.original.op_status) && (<div><i className={"fas fa-user-alt me-2"}></i><strong>{data.row.original.op_status}</strong></div>)}
                    </div>
                )
            },
            {
                Header: '操作', // 列的标题
                accessor: 'actions',
                Cell: (data: any) => (
                    <div hidden={data.row.original.op_waiting}>
                        <button
                            type="button" className="btn btn-xs btn-outline-primary me-2"
                            hidden={data.row.original['role'] == 'Admin' || data.row.original['role'] == 'Audit'}
                            onClick= {
                                () => {
                                    console.log(data.row)
                                    const newData = data.row.original
                                    setCurrentUser(newData.id)
                                    seIsUserModalEdit(true)
                                    setNewUserVisible(true)
                                    reset(newData)
                                }
                            }
                        >
                            <i className="mdi mdi-pencil"></i>
                        </button>
                        {
                            data.row.original['role'] == 'Admin' && (
                                <span className={"text-danger"}><strong>管理员用户，不可修改</strong></span>
                            )
                        }
                        {
                            data.row.original['role'] == 'Audit' && (
                                <span className={"text-danger"}><strong>审计员用户，不可修改</strong></span>
                            )
                        }
                        {false && (
                            <button
                                type="button" className="btn btn-xs btn-outline-danger"
                                onClick= {
                                    () => {
                                        swal
                                            .fire({
                                                title: `确定删除${data.row.original.name}吗?`,
                                                text: `一旦删除将无法找回`,
                                                icon: "警告",
                                                showCancelButton: true,
                                                confirmButtonColor: "#28bb4b",
                                                cancelButtonColor: "#f34e4e",
                                                confirmButtonText: "是的,删除!",
                                                cancelButtonText: "取消",
                                            })
                                            .then(function (result: { value: any }) {
                                                if (result.value) {
                                                    removeUsers([data.row.original.id])
                                                    swal.fire(
                                                        "已删除!",
                                                        "用户已经被删除了.",
                                                        "成功"
                                                    );
                                                }
                                            });
                                    }
                                }
                            >
                                <i className="mdi mdi-close"></i>
                            </button>
                        )}

                    </div>
                ),
            },
        ],
        []
    );

    const getGroups = useCallback(() => {
        auth_fetch_post_json(Conf.urlUsersGrouplist, {
        })
            .then((data: any) => {
                setGroups(data.groups)
                setAllGroups(data.flatGroups)
                setCurrentGroup(0)
                setCurrentGroupName('我的组织')
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);



    const getUsers = useCallback(() => {
        console.log("currentGroup " + currentGroup)
        auth_fetch_post_json(Conf.urlUsersUserList, {
            'group': currentGroup
        })
            .then((data: any) => {
                setUsers(data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [currentGroup]);




    useEffect(() => {
        getGroups();
    }, [getGroups]);


    useEffect(() => {
        getUsers();
    }, [getUsers, currentGroup]);


    const updateGroup = (id: number, text: string) => {
        auth_fetch_post_json(Conf.urlUsersGroupUpdate, {
            'id': id,
            "text": text
        })
            .then((r: any) => {
                setGroups(r.groups)
                setAllGroups(r.flatGroups)
            })
            .catch((error) => {
                swal.fire({
                    icon: "error",
                    title: "失败!",
                    text: error,
                    confirmButtonColor: "#4a4fea"
                });
            });
    }

    const newGroup = (parent: number) => {
        swal
            .fire({
                title: "新建部门",
                input: "text",
                showCancelButton: true,
                confirmButtonText: "提交",
                cancelButtonText: "取消",
                showLoaderOnConfirm: true,
                confirmButtonColor: "#4a4fea",
                cancelButtonColor: "#f34e4e",
                onConfirm: function (text: string) {
                  alert("")
                },
                allowOutsideClick: false,
            })
            .then(function (res: any) {
                if(res.isConfirmed && res.value!= "" && res.value != null && res.value != undefined) {
                    auth_fetch_post_json(Conf.urlUsersGroupNew, {
                        'parent': parent,
                        "text": res.value
                    })
                        .then((data: any) => {
                            console.log(data)
                            setGroups(data.groups)
                            setAllGroups(data.flatGroups)
                        })
                        .catch((error) => {
                            swal.fire({
                                icon: "success",
                                title: "失败!",
                                confirmButtonColor: "#4a4fea"
                            });
                        });
                }

            })

    }




    const removeGroup = (id: number) => {
        auth_fetch_post_json(Conf.urlUsersGroupRemove, {
            'id': id
        })
            .then((data:any) => {
                setGroups(data.groups)
                setAllGroups(data.flatGroups)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    const toggleNewUserModal = () => {
        setNewUserVisible(!newUserVisible)
    };

    const newUser = async (values: any, event: any) => {
        console.log(values)
        console.log(event)

        const jsonData: any = {}
        jsonData["currentGroup"] = currentGroup

        if(isUserModalEdit) {
            jsonData["updateGroup"] = values.group
        } else {
            jsonData["updateGroup"] = currentGroup
        }
        jsonData["name"] = values.name
        jsonData["password"] = values.password
        jsonData["email"] = values.email
        jsonData["phone"] = values.phone
        jsonData["ukeyEnable"] = values.ukeyEnable
        jsonData["ukey"] = values.ukey
        jsonData["desc"] = values.desc
        jsonData["role"] = values.role
        jsonData["enabled"] = values.enabled

        if (isUserModalEdit) {
            jsonData["id"] = currentUser
        }

        const url = isUserModalEdit? Conf.urlUsersUserUpdate : Conf.urlUsersUserNew

        auth_fetch_post_json(url, jsonData)
            .then((respJson: any) => {
                if(respJson["status"]) {
                    setUsers(respJson.data)
                    setNewUserVisible(false)
                } else {
                    swal.fire({
                        icon: "error",
                        title: "失败!",
                        text: respJson.reason
                    });
                }
            })
            .catch((error) => {
                swal.fire({
                    icon: "error",
                    title: "失败!",
                    text: error
                });
            })
        return false
    }

    const removeUsers = (ids: number[]) => {
        auth_fetch_post_json(Conf.urlUsersUserRemove, {
            'group': currentGroup,
            'ids': ids
        }).then((data: any) => {
            setUsers(data)
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const methods = useForm({});
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
    } = methods;


    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(appTitleSet('用户管理'))
    }, []);



    return (
        <>
            <Row>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h4 className="header-title">部门列表</h4>
                                    <p className="sub-header">
                                        {/*对部门进行增删改,可拖动排序.*/}
                                    </p>
                                    <Nestable
                                        items={groups}
                                        renderItem={x => (
                                            <RenderItem
                                                item={x.item}
                                                collapseIcon={x.collapseIcon}
                                                handler={x.handler}
                                                onEdit={(item, newName) => updateGroup(item.id, newName) }
                                                onNewClick={(item) => {
                                                        newGroup(item.id)
                                                    }
                                                }
                                                onRemove={(item) => {
                                                    swal
                                                        .fire({
                                                            title: "你确定删除吗?",
                                                            text: "一旦删除将无法找回!",
                                                            icon: "警告",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#28bb4b",
                                                            cancelButtonColor: "#f34e4e",
                                                            confirmButtonText: "是的,删除!",
                                                            cancelButtonText: "取消",
                                                        })
                                                        .then(function (result: { value: any }) {
                                                            if (result.value) {
                                                                removeGroup(item.id)
                                                                swal.fire(
                                                                    "已删除!",
                                                                    "这个部门已经被删除了.",
                                                                    "成功"
                                                                );
                                                            }
                                                        });

                                                        }
                                                    }
                                                onClick={item => {
                                                    setCurrentGroup(item.id)
                                                    setCurrentGroupName(item.text)
                                                }
                                            }
                                            />
                                        )}
                                        className="custom-dd dd font-13"
                                        collapsed={isCollapsed}
                                        onChange={arg=> {
                                            console.log(arg)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={9}>
                    <Card>
                        <Card.Header>
                            <h4 className="header-title">当前部门: {currentGroupName}</h4>


                            <button className={"btn btn-sm btn-danger me-2"}
                                    onClick={()=> {
                                        const selectedData = userTable.current!.getSelectedRows();
                                        const selectedIds = selectedData.map((item: any) => item.id);
                                        if(selectedIds.length == 0) {
                                            swal.fire({
                                                icon: "warning",
                                                title: "请在下表勾选要删除的用户",
                                                confirmButtonColor: "#4a4fea"
                                            });
                                            return
                                        } else {
                                            swal
                                                .fire({
                                                    title: "你确定删除这些用户吗?",
                                                    text: "一旦删除将无法找回!",
                                                    icon: "警告",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#28bb4b",
                                                    cancelButtonColor: "#f34e4e",
                                                    confirmButtonText: "是的,删除!",
                                                    cancelButtonText: "取消",
                                                })
                                                .then(function (result: { value: any }) {
                                                    if (result.value) {
                                                        removeUsers(selectedIds)
                                                        swal.fire(
                                                            "已删除!",
                                                            "用户已经被删除了.",
                                                            "成功"
                                                        );
                                                    }
                                                });
                                        }

                                    }}
                            ><i className={"mdi mdi-close"}></i></button>
                            {false && (<button  className={"btn btn-sm btn-primary me-2"}
                                     onClick = {
                                         () => {
                                             setCurrentUser(0)
                                             seIsUserModalEdit(false)
                                             setNewUserVisible(true)
                                             reset({})
                                         }
                                     }><i className={"mdi mdi-plus"}></i></button>)}


                        </Card.Header>
                        <Card.Body>
                            <Table
                                columns={columns}
                                data={users}
                                pageSize={25}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                isSelectable={true}
                                ref={userTable}
                            />
                            <Modal show={newUserVisible} onHide={toggleNewUserModal}>
                                <FormProvider {...methods}>
                                    <Form onSubmit={handleSubmit(newUser)}>
                                        <Modal.Header closeButton>
                                            <h4 className="modal-title">{isUserModalEdit? '编辑用户' : '添加用户'}</h4>
                                        </Modal.Header>
                                        <Modal.Body className="p-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="field-1" className="form-label">
                                                            用户名
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="field-1"
                                                            placeholder="输入用户名"
                                                            required
                                                            {...register("name", { required: true })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3" hidden>
                                                        <label htmlFor="field-2" className="form-label">
                                                            密码
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="field-2"
                                                            placeholder="输入密码"
                                                            required
                                                            {...register("password", { required: true })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="field-3" className="form-label">
                                                            邮箱
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="field-3"
                                                            placeholder="输入邮箱地址"
                                                            required
                                                            {...register("email", { required: true })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="field-phone" className="form-label">
                                                            电话
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="field-phone"
                                                            placeholder="输入电话号码"
                                                            required
                                                            {...register("phone", { required: true })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" hidden>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            type="switch"
                                                            id="default-checkbox1"
                                                            label="允许UKEY登录"
                                                            {...register("ukeyEnable", { required: false })}

                                                        />
                                                        <label htmlFor="field-ukey" className="form-label">
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="field-ukey"
                                                            placeholder="输入UKEY"
                                                            {...register("ukey", { required: false })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="field-role" className="form-label">
                                                        用户类型
                                                    </label>
                                                    <select
                                                        disabled={true}
                                                        id="field-role"
                                                        className="form-select mb-3"
                                                        {...register("role", { required: false })}

                                                    >
                                                        <option key="User" value="User">普通用户</option>
                                                        <option key="Admin" value="Admin">系统管理员</option>
                                                        <option key="Audit" value="Audit">审计管理员</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="field-group" className="form-label">
                                                        所在组
                                                    </label>
                                                    <select
                                                        id="field-group"
                                                        className="form-select mb-3"
                                                        {...register("group", { required: false })}

                                                    >
                                                        {
                                                            allGroups.map((item: any) => {
                                                                return (
                                                                    <option key={item.fullPath} value={item.id}>{item.fullPath}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                </div>
                                            </div>

                                            <div className={"row"}>
                                                <div className={"col-md-12"}>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            type="switch"
                                                            label="允许使用"
                                                            {...register("enabled", { required: false })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="">
                                                        <label htmlFor="field-7" className="form-label">
                                                            个人描述
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="field-7"
                                                            placeholder="输入一些个人介绍"
                                                            {...register("desc", { required: true })}

                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button
                                                type="button"
                                                className="btn btn-danger waves-effect"
                                                onClick={toggleNewUserModal}
                                            >
                                                关闭
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                保存
                                            </button>
                                        </Modal.Footer>
                                    </Form>
                                </FormProvider>
                            </Modal>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </>
    );
})

export default UsersApp;
