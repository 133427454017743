// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadable-audio-box-container {
    background-color: #333333;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadable-audio-box-audio-control {
    width: 100%;
    height: 40px;
    border-radius: 0;
    object-fit: contain;
}

.uploadable-audio-box-visualizer {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.uploadable-audio-box-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #333333;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    padding: 10px;
}



`, "",{"version":3,"sources":["webpack://./src/pages/apps/AudioTransOnce/UploadableAudioBox.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".uploadable-audio-box-container {\r\n    background-color: #333333;\r\n    overflow: hidden;\r\n    position: relative;\r\n    height: 100%;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.uploadable-audio-box-audio-control {\r\n    width: 100%;\r\n    height: 40px;\r\n    border-radius: 0;\r\n    object-fit: contain;\r\n}\r\n\r\n.uploadable-audio-box-visualizer {\r\n    width: 100%;\r\n    max-width: 500px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.uploadable-audio-box-placeholder {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100%;\r\n    width: 100%;\r\n    color: #333333;\r\n    font-size: 1.5rem;\r\n    font-weight: 500;\r\n    cursor: pointer;\r\n    padding: 10px;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
