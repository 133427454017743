import Select, { components, OptionProps, SingleValueProps } from "react-select";
import React, {useEffect, useState} from "react";
import { ChatUser, users } from "./data";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import useWebAppConfig, {ModelConfig} from "../../../utils/WebAppConfig";
import {set} from "react-hook-form";
import Draft from "draft-js";

export type ModelSelectorUseingMode = 'kb' | 'chat' | 'trans'
interface ModelSelectorProps {

    onModelChanged: (model: ModelConfig) => void
    mode: ModelSelectorUseingMode
    disabled?: boolean
}
export const ModelSelector = (props: ModelSelectorProps) => {
    const webCfg = useWebAppConfig()
    const [selectedModel, setSelectedModel] = useState<ModelConfig | null>(null)
    useEffect(() => {
        webCfg.loadWebAppConfig()

    }, []);

    useEffect(() => {
        if(props.mode == 'kb') {
            if (webCfg.modelsKb.length > 0) {
                setSelectedModel(webCfg.modelsKb[0])
                props.onModelChanged(webCfg.modelsKb[0])
            } else {
                setSelectedModel(null)
            }
        }
    }, [webCfg.modelsKb]);

    useEffect(() => {
        if(props.mode == 'chat') {
            if (webCfg.modelsChat.length > 0) {
                setSelectedModel(webCfg.modelsChat[0])
                props.onModelChanged(webCfg.modelsChat[0])
            } else {
                setSelectedModel(null)
            }
        }
    }, [webCfg.modelsChat]);


    useEffect(() => {
        if(props.mode == 'trans') {
            if (webCfg.modelsTrans.length > 0) {
                setSelectedModel(webCfg.modelsTrans[0])
                props.onModelChanged(webCfg.modelsTrans[0])
            } else {
                setSelectedModel(null)
            }
        }
    }, [webCfg.modelsTrans]);

    const ofDisplayNameInButton = () => {
        if(!selectedModel) {
            return "没有可用模型"
        }
        if(props.mode == 'trans') {
            return selectedModel.modelDisplayName
        } else if(props.mode == 'chat') {
            return selectedModel.modelDisplayName
        } else if(props.mode == 'kb') {
            return selectedModel.modelDisplayName
        } else {
            return selectedModel.modelDisplayName + '+未知模式'
        }
    }

    const ofDisplayNameInMenu = (model: ModelConfig) => {
        if(!model) {
            return "没有可用模型"
        }
        if(props.mode == 'trans') {
            return model.modelDisplayName
        } else if(props.mode == 'chat') {
            return model.modelDisplayName
        } else if(props.mode == 'kb') {
            return model.modelDisplayName
        } else {
            return model.modelDisplayName + '+未知模式'
        }
    }


    const ofModels = () => {
        if(props.mode == 'trans') {
            return webCfg.modelsTrans
        } else if(props.mode == 'chat') {
            return webCfg.modelsChat
        } else if(props.mode == 'kb') {
            return webCfg.modelsKb
        } else {
            return []
        }
    }

    return (
        <Dropdown as={ButtonGroup} size={"md"}>
            <Dropdown.Toggle disabled={props.disabled} variant="outline-secondary">
                {ofDisplayNameInButton()}
                <i className="mdi mdi-chevron-down"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>您还可以使用下面的基础大模型</Dropdown.Header>
                <Dropdown.Divider />
                {
                    ofModels().map((model: ModelConfig, index: number) => {
                        return (
                            <Dropdown.Item key={"prompt_demo_id_" + index} onClick={async () => {
                                props.onModelChanged(model)
                                setSelectedModel(model)
                            }}>{ofDisplayNameInMenu(model)}</Dropdown.Item>
                        )
                    })
                }
                {props.mode == 'trans' &&  <Dropdown.Item key={"prompt_demo_id_small_lang"} onClick={async () => {
                    props.onModelChanged({
                        'modelName': 'nllb',
                        'modelDisplayName': '生僻语种翻译',
                    })
                    setSelectedModel({
                        'modelName': 'nllb',
                        'modelDisplayName': '生僻语种翻译',
                    })
                }}>生僻语种翻译（支持多达200种语言）</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    )
}