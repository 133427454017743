import {FormProvider} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import Select from "react-select";
import {UploadingManyUploadedItem} from "../../../utils/uploadingmany";
import Spinner from "../../../components/Spinner";
import React, {useEffect} from "react";
import {OptionType, RobotSelector} from "./RobotSelector";
import {ChatUser, users} from "./data";
import {auth_fetch_post_json} from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";

export interface ChatNewDialogProps {
    visible: boolean
    onHide: () => void
    onCreated: (dialogId: number, robotId: string) => void
    where: 'kb'| 'chat'
}
export const ChatNewDialog = (props: ChatNewDialogProps) => {
    const [selectedRobot, setSelectedRobot] = React.useState<OptionType>({
        value: 'genericHelperA',
        label: '全能助手',
        user: users[0]
    })
    const handleOnOkClicked = async () => {
        if(selectedRobot) {
            console.log(selectedRobot)
            const jo = await auth_fetch_post_json(Conf.urlApiChat2DialogCreate, {
                'dialogName': '未命名会话',
                'robotId': selectedRobot.user.robotId,
                'where': props.where
            }) as any
            console.log(jo)
            props.onCreated(jo.dialogId, selectedRobot.user.robotId!)
            props.onHide()
        }
    }



    return (
        <Modal show={props.visible} onHide={props.onHide} backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <h4 className="modal-title">新建对话</h4>
            </Modal.Header>
            <Modal.Body className="p-4">
                使用Agent:
                <RobotSelector
                    value={selectedRobot}
                    onChange={u=> setSelectedRobot(u)}></RobotSelector>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        props.onHide()
                    }}
                >
                    取消
                </Button>
                <Button onClick = {handleOnOkClicked}>确定</Button>

            </Modal.Footer>

        </Modal>
    )
}