import { useEffect } from "react";
import "@fullcalendar/react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { appTitleSet } from "../../../redux/actions";
import Conf from "../Utils/Conf";
import useWebAppConfig from "../../../utils/WebAppConfig";

export const AiNewsApp = () => {
    const dispatch = useDispatch<AppDispatch>();
    const webAppConfig = useWebAppConfig()
    useEffect(() => {
        dispatch(appTitleSet('越南要闻'))
        webAppConfig.loadWebAppConfig()
    }, []);
    
    return (
        <iframe
            src={webAppConfig.urlAiNews}
            width="100%"
            height="100%"
        >

        </iframe>
    );
};
