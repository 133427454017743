import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea } from "../WidgetAppParam";

const NetSecToolApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextArea("outline", "你想完成什么功能?", "请输入您要完成的功能的介绍", "功能"),
    ];

    const template = "NetSecTool";
    const title = "网络安全助手";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default NetSecToolApp;