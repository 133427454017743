import React from "react";
import { BaseWidgetApp } from "../BaseWidgetApp";
import { WidgetAppParam, WidgetAppParamTextArea, WidgetAppParamTextLine } from "../WidgetAppParam";

const StudyMindMapApp: React.FC = () => {
    const params = (): WidgetAppParam[] => [
        new WidgetAppParamTextLine("subject", "教学主题", "输入学习的主题", "教学主题"),
        new WidgetAppParamTextArea("content", "学的大致内容", "输入本次教学的大致内容", "教学的大致内容"),
    ];

    const template = "StudyMindMap";
    const title = "教学思维导图";

    return (
        <BaseWidgetApp
            avatar=""  // 如果需要avatar，请提供适当的值
            template={template}
            title={title}
            params={params}
        />
    );
};

export default StudyMindMapApp;