import Select from "react-select";
import { Button } from "react-bootstrap";
import React, {useEffect, useState} from "react";
import DocImportModal from "../pages/apps/DocBatch/DocImportModal";
import './TransLangSelectGroup.css';
import useT2tt from "./t2tt";
import {ModelSelector} from "../pages/apps/AiChat/ModelSelector";
import {ModelConfig} from "./WebAppConfig";

export interface LangInfo {
    value: string;
    label: string;
}


export interface Engine {
    value: string
    label: string
}


interface TransLangSelectGroupProps {
    defaultFromLang: LangInfo;
    defaultToLang: LangInfo;
    defaultEngine: Engine;
    onFromLangChanged: (lang: LangInfo) => void;
    onToLangChanged: (lang: LangInfo) => void;
    onEngineChanged: (engine: Engine) => void;
    onModelConfigChanged: (modelConfig: ModelConfig) => void;
}


const TransLangSelectGroup = (props: TransLangSelectGroupProps) => {
    const [fromLang, setFromLang] = useState<LangInfo>(props.defaultFromLang);
    const [toLang, setToLang] = useState<LangInfo>(props.defaultToLang);
    const [currentEngine, setCurrentEngine] = useState<Engine>(props.defaultEngine);
    const t2tt = useT2tt()
    const [modelConfig, setModelConfig] = useState<ModelConfig>()
    const defaultEngine = {
        value: 'llm',
        label: 'LLM大模型'
    }

    useEffect(() => {
        if(t2tt.t2ttInfo) {
            setTimeout(() => {
                setFromLang(t2tt.t2ttInfo['engines_info']['llm']['default_src'])
                setToLang(t2tt.t2ttInfo['engines_info']['llm']['default_tgt'])
            }, 1000);

        }
    }, [t2tt.t2ttInfo]);


    useEffect(() => {
        if(!modelConfig) {
            setCurrentEngine(defaultEngine)
            return
        }
        if(modelConfig.modelName!='nllb') {
            setCurrentEngine(defaultEngine)
        } else {
            setCurrentEngine({
                value: 'nllb',
                label: 'nllb'
            })
        }
    }, [modelConfig]);

    return (
        <div className="trans-lang-select-group-container">
            <ModelSelector mode={'trans'} onModelChanged={m=> {
                setModelConfig(m)
                props.onModelConfigChanged(m)
            }}></ModelSelector>

            <Select
                className="react-select react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                isSearchable={true}
                value={fromLang}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: '200px',
                    }),
                }}
                options={
                    t2tt.t2ttInfo?
                        t2tt.t2ttInfo[currentEngine.value]['src_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['src_langs'][code]['language']}))
                        :[]
                }
                onChange={(e) => {
                    if (e) {
                        setFromLang(e);
                        props.onFromLangChanged(e);
                    }
                }}
            ></Select>

            <Button
                onClick={() => {
                    props.onToLangChanged(fromLang);
                    props.onFromLangChanged(toLang);
                    const tmpLang = fromLang;
                    setFromLang(toLang);
                    setToLang(tmpLang);
                }}
                variant="default"
                className="btn-info waves-effect waves-light"
            >
                <i className="fas fa-exchange-alt"></i>
            </Button>

            <Select
                className="react-select react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                isSearchable={true}
                value={toLang}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: '200px',
                    }),
                }}
                options={
                    t2tt.t2ttInfo?
                        t2tt.t2ttInfo[currentEngine.value]['tgt_codes'].map((code: any) => ({value: code, label: t2tt.t2ttInfo[currentEngine.value]['tgt_langs'][code]['language']}))
                        :[]
                }
                onChange={(e) => {
                    if (e) {
                        setToLang(e);
                        props.onToLangChanged(e);
                    }
                }}
            ></Select>
        </div>
    );
};

export default TransLangSelectGroup;