// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hot-tool-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1;
}

.hot-tool-container-toolbar {
    display: flex;
    gap: 8px;
    flex-direction: row;
}


.hot-tool-container-table {
    flex: 1 1
}`, "",{"version":3,"sources":["webpack://./src/pages/apps/HotTool/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,SAAO;AACX;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;;AAGA;IACI;AACJ","sourcesContent":[".hot-tool-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 8px;\r\n    flex: 1;\r\n}\r\n\r\n.hot-tool-container-toolbar {\r\n    display: flex;\r\n    gap: 8px;\r\n    flex-direction: row;\r\n}\r\n\r\n\r\n.hot-tool-container-table {\r\n    flex: 1\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
