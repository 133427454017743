import { ChatMessage, ChatUser, UserInputFile } from "./data";
import marked from "marked";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import hljs from "highlight.js";
import { auth_fetch_post_json } from "../../../utils/auth_fetch";
import Conf from "../Utils/Conf";
import classNames from "classnames";
import { Card, Col, Collapse, Dropdown, Row } from "react-bootstrap";
import classnames from "classnames";
import ChatFileInBubble from "./ChatFileInBubble";
import Spinner from "../../../components/Spinner";
import { Link } from "react-router-dom";
const from_cache = {

}

export const ChatMessageComponent = ({
    message,
    toUser,
}: {
    message: ChatMessage;
    toUser: ChatUser;
}) => {

    const getMarkdownText = useCallback((src: string) => {
        const rawMarkup = (marked as any)(src, { sanitize: true });
        return { __html: rawMarkup };
    }, []);

    const markdownContent = useMemo(
        () => getMarkdownText(message.message.value + ""),
        [message.message.value, getMarkdownText]
    );

    (marked as any).setOptions({
        gfm: true, // 启用 GitHub Flavored Markdown
        breaks: false, // 可以根据需要启用 GFM 换行符
        // 其他选项...
    });

    useEffect(() => {
        // 应用高亮
        document.querySelectorAll("pre code").forEach(block => {
            hljs.highlightBlock(block as HTMLElement);
        });
        document.querySelectorAll('table').forEach(table => {
            table.classList.add('table-style');
        });
    }, []);


    const DocsItem = ({ from_uuid }: { from_uuid: string }) => {
        const [docs, setDocs] = useState<any[]>([])
        const [collapse, setCollapse] = useState<boolean>(false);
        const toggleContent = () => {
            setCollapse(!collapse);
        };
        useEffect(() => {
            if (from_cache.hasOwnProperty(from_uuid)) {
                // @ts-ignore
                setDocs(from_cache[from_uuid])
            } else {
                auth_fetch_post_json(Conf.urlChatFromInfo, { from_uuid: from_uuid })
                    .then((data: any) => {
                        console.log(data)
                        // @ts-ignore
                        from_cache[from_uuid] = data
                        setDocs(data)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }, []);
        return (
            <div>
                <div><a href="#" onClick={toggleContent}>
                    <span style={{ display: "inline" }}>信息来源</span><i className={classNames("mdi", {
                        "mdi-chevron-up": collapse,
                        "mdi-chevron-down": !collapse,
                    })} style={{ display: "inline" }}></i>
                </a></div>

                <Collapse in={collapse}>
                    <div>
                        {docs.map((doc, index) => {
                            return (
                                <div key={"doc" + index} dangerouslySetInnerHTML={getMarkdownText(doc)}></div>
                            )
                        })}
                    </div>
                </Collapse>

            </div>
        )
    }

    return (
        <li className={classnames("clearfix", { odd: false && message.from.id === toUser.id })}>
            <div className="chat-avatar">
                <img src={message.from.avatar} className="rounded" alt="" />
            </div>

            <div className="conversation-text">
                {message.message.type === "text" && (
                    <div className="ctext-wrap">
                        <i>{message.from.name}</i>
                        <Row>
                            {
                                message.files?.map((file: UserInputFile) => {
                                    return (
                                        <Col xl={12} lg={12}>
                                            <ChatFileInBubble key={file.fileId} userInputFile={file}></ChatFileInBubble>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        {/*message.message.type === "text" && <ReactMarkdown children={message.message.value + ""} rehypePlugins={[remarkGfm]} ></ReactMarkdown>*/}
                        {message.message.type === "text" && (
                            <div dangerouslySetInnerHTML={markdownContent} ></div>
                        )}
                        {message.from_uuid && <DocsItem from_uuid={message.from_uuid}></DocsItem>}

                    </div>
                )}
                {
                    message.message.type === "typing" && <div className="ctext-wrap">
                        <i>{message.from.name}</i>
                        <span><Spinner className="spinner-border-sm" /></span>
                    </div>
                }

                {
                    message.message.type === "cancel" && <div className="ctext-wrap">
                        <i>{message.from.name}</i>
                        <span className={"text-danger"}>已取消</span>
                    </div>
                }

                {message.message.type === "file" && (
                    <Card className="mt-2 mb-1 shadow-none border text-start">
                        <div className="p-2">
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                    <div className="avatar-sm">
                                        <span className="avatar-title bg-primary rounded">
                                            .ZIP
                                        </span>
                                    </div>
                                </Col>
                                <Col className="ps-0">
                                    <Link to="#" className="text-muted fw-bold">
                                        {message.message.value.file}
                                    </Link>
                                    <p className="mb-0">{message.message.value.size}</p>
                                </Col>
                                <Col className="col-auto">
                                    <Link to="#" className="btn btn-link btn-lg text-muted">
                                        <i className="dripicons-download"></i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                )}
            </div>


            <Dropdown
                className="conversation-actions"
                align={message.from.id === toUser.id ? "start" : "end"}
            >

                <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                    <i className="mdi mdi-dots-vertical font-16"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {

                        const textarea = document.createElement('textarea');
                        textarea.value = message.message.value;
                        document.body.appendChild(textarea);
                        textarea.select();
                        document.execCommand('copy');
                        document.body.removeChild(textarea);
                    }}>
                        复制信息
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </li>
    );
};